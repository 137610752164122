/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

import { styled } from '@mui/material';

export const AssociateFilterField = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '0.75rem',
  alignItems: 'center',
}));
