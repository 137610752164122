/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

import { Box, CircularProgress, IconButton } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DetailsContainer,
  Divider,
  DurationText,
  Fields,
  FieldsContainer,
  HeaderContainer,
  HorizantalLine,
  LogoContainer,
  MediaContainer,
  MediaText,
  RequestText,
  RevisionRequestContainer,
  RevisionText,
} from '../CreateBid.Style';

import { Details, HeadingText } from '../../../Jobs/Components/JobDetails/OneTimeJobDetails.Style';

import { Icons } from '../../../../Shared/Constants/Icons';

import { isEmpty } from 'lodash';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { BidStatus } from '../../../../API';
import DateSelection from '../../../../Shared/Components/Common/DatePicker/DateSelection';
import MediaPopup from '../../../../Shared/Components/Common/MediaPopUp/MediaPopUp';
import theme from '../../../../Shared/Themes/theme';
import {
  convertDateFormatToIso,
  fetchDataFromS3Bucket,
  formatDate,
  getAuthData,
  getStoredCustomerDetails,
  isDateBefore,
} from '../../../../Shared/Utilities/utils';
import { DatePickerContainer } from '../../../Jobs/Components/AddEditOneTimeJob/AddEditOneTimeJob.Style';
import { FieldNames, IDetailsProps } from '../../../Jobs/Models/AddEditOneTimeJob.Model';
import JobCreateApiService from '../../../Jobs/Services/JobCreateService';
import Modal from '../../../Jobs/Utilities/Shared/Components/Modal';
import SnackbarMessage from '../../../../Shared/Components/Common/SnackbarMessage/SnackbarMessage';
import { utc } from 'moment';
import { TagNames } from '../../../../Shared/Constants/App';

type MediaType = 'photo' | 'video';

const BidHeader = (props: {
  bidDetails: any;
  handleModifyJobDates: (startDate: string, stopDate: string) => void;
}): JSX.Element => {
  const { t } = useTranslation(['vendor', 'dashboard']);
  const { bidDetails } = props;
  const {
    address: { county = '', addressName = '' } = {},
    lastActivity,
    // startDate,
    // stopDate,
    revisionRequests,
    jobId,
    subJobId = '',
    bidStatus,
    tag,
  } = bidDetails || {};
  const [startDate, setStartDate] = useState(props.bidDetails.startDate);
  const [stopDate, setStopDate] = useState(props.bidDetails.stopDate);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [currentMediaIndex, setCurrentMediaIndex] = useState(0);
  const [mediaType, setMediaType] = useState<MediaType>('photo');
  const [images, setImages] = useState<any[]>([]);
  const [videos, setVideos] = useState<any[]>([]);

  const [isTablet, setIsTablet] = useState(false);
  const [isMediaLoading, setIsMediaLoading] = useState<boolean>(false);
  const { isDistrictManager } = getAuthData();
  const { handleSubmit, control, setError, setValue } = useForm({
    defaultValues: {
      startDate: convertDateFormatToIso(startDate) ?? null,
      endDate: convertDateFormatToIso(stopDate) ?? null,
    },
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [isEditDateModalOpen, setIsEditDateModalOpen] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchAllJobAttachments();
    fetchJobDates('fetchingDatesOnLoad');
  }, []);

  const fetchJobDates = async (type?: string): Promise<void> => {
    const { customerId = '' } = getStoredCustomerDetails();
    try {
      const jobSummaryResponse = await JobCreateApiService.jobSummaryV2(customerId, jobId);
      const startDate = formatDate(jobSummaryResponse.data.startDate);
      const stopDate = formatDate(jobSummaryResponse.data.stopDate);
      setStartDate(formatDate(jobSummaryResponse.data.startDate));
      setStopDate(formatDate(jobSummaryResponse.data.stopDate));
      setValue(FieldNames.startDate, startDate);
      setValue(FieldNames.endDate, stopDate);
      if (!type) {
        props.handleModifyJobDates(jobSummaryResponse.data.startDate, jobSummaryResponse.data.stopDate);
      }
    } catch (e) {
      console.log('error while fetcing job dates:', e);
    }
  };

  const fetchAllJobAttachments = async (): Promise<void> => {
    try {
      setIsMediaLoading(true);
      const updatedJobId = bidDetails.jobId;
      const allJobAttachmentInput = { jobIds: [updatedJobId] };
      const attachmentResponse = await JobCreateApiService.getAllJobAttachments(allJobAttachmentInput);
      if (isEmpty(attachmentResponse.errors)) {
        const updatedDetails = await Promise.all(
          attachmentResponse.data.map(async (details: IDetailsProps) => {
            const attachmentUrl = await fetchDataFromS3Bucket(navigate, details?.url ?? '');
            return {
              ...details,
              url: attachmentUrl,
              id: details.attachmentId,
              attachmentId: details.attachmentId,
              responseUrl: details.url,
            };
          })
        );
        const imagesList: any[] = [];
        const videosList: any[] = [];
        updatedDetails.forEach((attachment: any) => {
          if (attachment.mdAttachmentType.includes('image')) {
            imagesList.push(attachment);
          } else {
            videosList.push(attachment);
          }
        });
        setImages([...images, ...imagesList]);
        setVideos([...videos, ...videosList]);
        setIsMediaLoading(false);
      } else {
        setIsMediaLoading(false);
      }
    } catch (e) {
      setIsMediaLoading(false);
      console.log('error', e);
    }
  };

  const checkScreenSize = useCallback(() => {
    setIsTablet(window.innerWidth <= 1024);
  }, []);

  const openMediaPopup = (type: MediaType): void => {
    const mediaArray = type === 'photo' ? images : videos;
    if (mediaArray.length) {
      setPopupOpen(true);
      setMediaType(type);
    }
  };

  const closeMediaPopup = (): void => {
    setPopupOpen(false);
    setCurrentMediaIndex(0);
  };

  const showMedia = (direction: 'previous' | 'next'): void => {
    setCurrentMediaIndex((prevIndex) => {
      const mediaArray = mediaType === 'photo' ? images : videos;
      return direction === 'previous'
        ? (prevIndex - 1 + mediaArray?.length) % mediaArray?.length
        : (prevIndex + 1) % mediaArray?.length;
    });
  };

  useEffect(() => {
    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);
    return () => {
      window.removeEventListener('resize', checkScreenSize);
    };
  }, [checkScreenSize]);

  const handleDateError = () => {
    setError(FieldNames.endDate, {
      type: 'validate',
      message: 'End date cannot be before start date.',
    });
    setIsLoading(false);
  };

  const handleApiError = (): boolean => {
    setSnackbarMessage('Error occurred while updating Job Date');
    setSnackbarSeverity('error');
    setSnackbarOpen(true);
    setIsLoading(false);
    return false;
  };

  const updateJobDates = async (startDate: string, endDate: string, jobId: string): Promise<boolean> => {
    const { customerId = '' } = getStoredCustomerDetails();
    const updateJobResponse = await JobCreateApiService.updateJob(customerId, jobId, {
      startDate: startDate ?? null,
      stopDate: endDate ?? null,
    });
    if (!isEmpty(updateJobResponse.errors)) {
      return handleApiError();
    }
    return true;
  };

  const handleChangeDate = async (dateDetails: any): Promise<void> => {
    const { startDate, endDate } = dateDetails;

    setIsLoading(true);
    const formatStartDate = formatDate(startDate, 'YYYY-MM-DD');
    const formatEndDate = formatDate(endDate, 'YYYY-MM-DD');

    if (startDate && isDateBefore(endDate, startDate)) {
      handleDateError();
      return;
    }

    try {
      const updateJobDateResponse = await updateJobDates(formatStartDate, formatEndDate, jobId);
      if (!updateJobDateResponse) return;

      if (subJobId) {
        const updateSubJobDateResponse = await updateJobDates(formatStartDate, formatEndDate, subJobId);
        if (!updateSubJobDateResponse) return;
      }
      await fetchJobDates();
      setIsEditDateModalOpen(false);
    } catch (error) {
      handleApiError();
    } finally {
      setIsLoading(false);
    }
  };

  const handleOpenEditDateModal = (): void => {
    setIsEditDateModalOpen(true);
  };

  const handleClose = (): void => {
    setValue(FieldNames.startDate, startDate);
    setValue(FieldNames.endDate, stopDate);
    setIsEditDateModalOpen(false);
  };

  const renderEditDateHeaderContent = (): JSX.Element => {
    return <HeadingText>{t('assignJob:shiftTimings')}</HeadingText>;
  };

  const renderEditDateBodyContent = (): JSX.Element => {
    return (
      <>
        <Details>{t('assignJob:dateRangeToCompleteJobWithin')}</Details>
        <DatePickerContainer>
          <DateSelection
            requiredText={t('oneTimeJob:startDateFieldReq')}
            control={control}
            isExcludePastDate={false}
            fieldName={FieldNames.startDate ?? null}
            label={t('calendar:startDate')}
            iconColor="black"
          />
          <DateSelection
            requiredText={t('oneTimeJob:endDateFieldReq')}
            control={control}
            isExcludePastDate={false}
            fieldName={FieldNames.endDate ?? null}
            label={t('calendar:endDate')}
            iconColor="black"
          />
        </DatePickerContainer>
      </>
    );
  };

  return (
    <HeaderContainer sx={{ position: 'relative' }}>
      {bidStatus === BidStatus.V && (
        <Box
          sx={{
            position: 'absolute',
            right: 0,
            top: '-1em',
            padding: '0.5em',
            backgroundColor: theme.palette.warning.light,
          }}>
          {t('dashboard:revisionRequested')}
        </Box>
      )}
      <DetailsContainer>
        <LogoContainer>
          <img src={Icons.BuildingImg} />
        </LogoContainer>
        <FieldsContainer>
          <Fields sx={{ gap: '1.5rem' }}>
            <Box>
              <HeadingText sx={{ color: theme.palette.text.secondary }}>
                {addressName}{' '}
                <span
                  style={{
                    fontWeight: theme.typography.fontWeightBold,
                    fontSize: theme.typography.h4?.fontSize,
                    color: theme.palette.text.primary,
                  }}>
                  {county}
                </span>
              </HeadingText>
            </Box>
            <Box sx={{ display: 'flex', whiteSpace: 'nowrap' }}>
              {!isTablet && (
                <DurationText>
                  <>
                    {isDistrictManager && tag !== TagNames.RejectOrRequestRevision && (
                      <IconButton onClick={() => handleOpenEditDateModal()}>
                        <img
                          src={Icons.EditBlack}
                          alt={t('altTexts:edit')}
                          style={{ width: '1.3rem', height: '1.3rem', color: theme.palette.secondary.contrastText }}
                        />
                      </IconButton>
                    )}
                  </>
                  {t('vendor:startAndEndDate')} : {formatDate(startDate)} to {formatDate(stopDate)}
                </DurationText>
              )}
              <Modal
                open={isEditDateModalOpen}
                onClose={handleClose}
                primaryButtonLoading={isLoading}
                primaryButtonLabel={t('dashboard:changeDate')}
                secondaryButtonLabel={t('assignJob:cancel')}
                renderHeader={renderEditDateHeaderContent()}
                renderBody={renderEditDateBodyContent()}
                primaryAction={handleSubmit(handleChangeDate)}
                secondaryAction={handleClose}
              />
              <SnackbarMessage
                open={snackbarOpen}
                successMessage={snackbarMessage}
                errorMessage={snackbarMessage}
                severity={snackbarSeverity}
                onClose={() => setSnackbarOpen(false)}
              />
            </Box>
          </Fields>

          {/* If it is tablet screen */}
          {isTablet && (
            <Fields sx={{ marginBottom: '1rem' }}>
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <MediaContainer>
                  <DurationText>
                    {t('vendor:startAndEndDate')} : {formatDate(startDate)} to {formatDate(stopDate)}
                    <>
                      {isDistrictManager && (
                        <IconButton onClick={() => handleOpenEditDateModal()}>
                          <img
                            src={Icons.EditBlack}
                            alt={t('altTexts:edit')}
                            style={{ width: '1.3rem', height: '1.3rem', color: theme.palette.secondary.contrastText }}
                          />
                        </IconButton>
                      )}
                    </>
                  </DurationText>
                </MediaContainer>
              </Box>
            </Fields>
          )}

          <Fields>
            <Box sx={{ display: 'flex' }}>
              <Box
                sx={{
                  marginRight: '1rem',
                  fontFamily: theme.typography.fontFamily,
                  fontWeight: theme.typography.fontWeightBold,
                }}>
                {t('vendor:jobVisuals')}:
              </Box>
              {isMediaLoading ? (
                <CircularProgress size={30} sx={{ color: theme.palette.primary.dark }} />
              ) : (
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <MediaContainer onClick={() => openMediaPopup('photo')}>
                    <img src={Icons.PhotosIcon} alt={t('altTexts:galleryIcon')} />
                    <MediaText>
                      {images?.length} {images?.length <= 1 ? t('dashboard:photo') : t('dashboard:photos')}
                    </MediaText>
                  </MediaContainer>
                  <Divider />
                  <MediaContainer onClick={() => openMediaPopup('video')}>
                    <img src={Icons.VideoIcon} alt={t('altTexts:videoIcon')} />
                    <MediaText>
                      {videos?.length} {videos?.length <= 1 ? t('dashboard:video') : t('dashboard:videos')}
                    </MediaText>
                  </MediaContainer>
                </Box>
              )}
            </Box>
          </Fields>
          {/* <Fields>
            <div style={{ display: 'flex' }}>
              <div style={{ marginRight: '1em', fontFamily: theme.typography.fontFamily, }}>Facility Visuals:</div>
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <MediaContainer onClick={() => openMediaPopup('photo')}>
                  <img src={PhotosIcon} alt={t('altTexts:galleryIcon')} />
                  <MediaText>{images?.length} Photos</MediaText>
                </MediaContainer>
                <Divider />
                <MediaContainer onClick={() => openMediaPopup('video')}>
                  <img src={VideoIcon} alt={t('altTexts:videoIcon')} />
                  <MediaText>{videos?.length} Videos</MediaText>
                </MediaContainer>
              </Box>
            </div>
          </Fields> */}
          {/* <hr /> */}
        </FieldsContainer>
        <MediaPopup
          isOpen={isPopupOpen}
          onClose={closeMediaPopup}
          currentMediaIndex={currentMediaIndex}
          media={mediaType === 'photo' ? images : videos}
          showPreviousMedia={() => showMedia('previous')}
          showNextMedia={() => showMedia('next')}
          numMedia={mediaType === 'photo' ? images?.length : videos?.length}
          type={mediaType}
        />
      </DetailsContainer>
      {revisionRequests?.length > 0 && <HorizantalLine></HorizantalLine>}
      {revisionRequests?.length > 0 &&
        lastActivity &&
        revisionRequests.map((revision: any, index: number) => {
          return (
            <RevisionRequestContainer key={index}>
              <RevisionText>{t('vendor:revisionRequest')}</RevisionText>
              <RequestText>
                <div>{revision.request}</div>
                <div style={{ width: '9.5rem' }}>{utc(revision.requestTime).format('DD MMM YYYY, h:mma')}</div>
              </RequestText>
            </RevisionRequestContainer>
          );
        })}
    </HeaderContainer>
  );
};

export default BidHeader;
