/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

import { RouteType } from './config';
import HomePage from '../Modules/Customer/Components/Dashboard/Dashboard';
import Facilities from '../Modules/Facility/Components/FacilityHome/FacilityHome';
import AddEditOneTimeJob from '../Modules/Jobs/Components/AddEditOneTimeJob/AddEditOneTimeJob';
import TaskDetailsPage from '../Modules/Jobs/Components/TaskDetails/TaskDetailsPage';
import OneTimeJobDetails from '../Modules/Jobs/Components/JobDetails/OneTimeJobDetails';
import CreateBid from '../Modules/Vendor/Components/BidNow/BidNow';

import { Icons } from '../Shared/Constants/Icons';
// import LeaderboardOutlinedIcon from '@mui/icons-material/LeaderboardOutlined';

import CompanyView from '../Modules/Company/Components/CompanyView/CompanyView';
import UserView from '../Modules/Company/Components/UserView/UserView';
import JobOverviewListView from '../Modules/Jobs/Components/JobsListView/JobsListView';
import JobsView from '../Modules/Jobs/Components/JobView/JobsView';
import TasksReview from '../Modules/Jobs/Components/TasksReview/TasksReview';
import JobDetailsOverview from '../Modules/Jobs/Components/JobDetailsOverView/JobDetailsOverview';
import JobsIssuesRaised from '../Modules/Jobs/Components/IssuesRaised/JobsIssuesRaised';
import VendorJobs from '../Modules/Vendor/Components/Jobs/Jobs';
import BidReviewPage from '../Modules/Vendor/Components/BidReview/BidReview';
import AddUserPage from '../Modules/Customer/Components/AddUser/AddUser';
import ViewFacilityDetails from '../Modules/Customer/Components/ViewFacilityDetails/ViewFacilityDetails';
import AddFacilityPage from '../Modules/Facility/Components/AddFacility/AddFacility';
import UserDetails from '../Modules/Company/Components/Users/UserDetails/UserDetails';
import ConversationsPage from '../Modules/Jobs/Components/Conversations/ConversationsPage';
import AddAccessFacilityInfo from '../Modules/Customer/Components/ViewFacilityDetails/AddAccessFacilityInfo';
// import Analytics from '../Modules/Analytics/Components/Analytics';
import AddFacilityDetails from '../Modules/Customer/Components/ViewFacilityDetails/AddFacilityDetails';
import MarketplacePage from '../Modules/Vendor/Components/Marketplace/MarketplacePage';
import Reports from '../Modules/Reports/Components/Reports';

const appRoutes: RouteType[] = [
  {
    index: true,
    element: <HomePage />,
    state: 'home',
    users: ['Customer', 'Vendor'],
    roles: ['Admin'],
  },
  {
    path: '/home',
    element: <HomePage />,
    state: 'home',
    users: ['Customer', 'Vendor'],
    roles: ['Admin'],
    sidebarProps: {
      displayText: 'homePage:home',
      icon: <img src={Icons.HomeIcon} alt="Home Icon" style={{ width: '1.5rem', height: '1.5rem' }} />,
    },
  },
  {
    path: '/company',
    element: <CompanyView />,
    state: 'companyUsers',
    users: ['Customer', 'Vendor'],
    roles: ['Admin'],
    sidebarProps: {
      displayText: 'homePage:company',
      icon: <img src={Icons.FacilityIcon} alt="Company Icon" style={{ width: '1.5rem', height: '1.5rem' }} />,
    },
  },
  {
    path: '/company/users',
    element: <CompanyView />,
    state: 'companyUsers',
    users: ['Customer', 'Vendor'],
    roles: ['Admin'],
  },
  {
    path: '/company/userDetails',
    element: <UserDetails />,
    state: 'companyUserDetails',
    users: ['Customer', 'Vendor'],
    roles: ['Admin'],
  },
  {
    path: '/company/facilities',
    element: <CompanyView />,
    state: 'companyFacilities',
    users: ['Customer'],
    roles: ['Admin'],
  },
  {
    path: '/company/user',
    element: <UserView />,
    state: 'companyUsers',
    users: ['Customer', 'Vendor'],
    roles: ['Admin'],
  },
  {
    path: '/reports',
    element: <Reports />,
    state: 'reportsView',
    users: ['Vendor'],
    roles: ['Admin'],
    sidebarProps: {
      displayText: 'homePage:reports',
      icon: <img src={Icons.GraphIcon} alt="Home Icon" style={{ width: '1.5rem', height: '1.5rem' }} />,
    },
  },
  {
    path: '/jobs-view/onetime',
    element: <JobsView />,
    state: 'onetimejobsview',
    users: ['Customer'],
    roles: ['Admin'],
    sidebarProps: {
      displayText: 'homePage:jobs',
      icon: <img src={Icons.JobsIcon} alt="Home Icon" style={{ width: '1.5rem', height: '1.5rem' }} />,
    },
  },
  {
    path: '/jobs-view/recurring',
    element: <JobsView />,
    state: 'recurringjobsview',
    users: ['Customer'],
    roles: ['Admin'],
  },
  {
    path: '/jobs-view/completed-jobs',
    element: <JobsView />,
    state: 'completedjobsview',
    users: ['Customer'],
    roles: ['Admin'],
  },
  {
    path: '/onetimejobdetails',
    element: <OneTimeJobDetails />,
    state: 'onetimejobdetails',
    users: ['Customer'],
    roles: ['Admin'],
  },
  {
    path: '/onetimejobdetails/bids',
    element: <OneTimeJobDetails />,
    state: 'onetimejobdetailsBids',
    users: ['Customer'],
    roles: ['Admin'],
  },
  {
    path: 'conversations',
    element: <ConversationsPage />,
    state: 'conversations',
    users: ['Customer'],
    roles: ['Admin'],
  },
  {
    path: '/task-details',
    element: <TaskDetailsPage />,
    state: 'taskdetails',
    users: ['Customer'],
    roles: ['Admin'],
  },
  {
    path: '/facilities',
    element: <Facilities />,
    state: 'facilities',
    users: ['Customer'],
    roles: ['Admin'],
  },
  {
    path: '/createOneTimeJob',
    element: <AddEditOneTimeJob />,
    state: 'jobs',
    users: ['Customer'],
    roles: ['Admin'],
  },
  {
    path: '/marketplace/recommended-jobs',
    element: <MarketplacePage />,
    state: 'recommendedjobsview',
    users: ['Vendor'],
    roles: ['Admin'],
    sidebarProps: {
      displayText: 'homePage:marketplace',
      icon: <img src={Icons.MarketPlaceIcon} alt="Home Icon" style={{ width: '1.5rem', height: '1.5rem' }} />,
    },
  },
  {
    path: '/marketplace/bids-in-progress',
    element: <MarketplacePage />,
    state: 'bidsinprogressview',
    users: ['Vendor'],
    roles: ['Admin'],
  },
  {
    path: '/marketplace/all-jobs',
    element: <MarketplacePage />,
    state: 'alljobsview',
    users: ['Vendor'],
    roles: ['Admin'],
  },
  {
    path: '/marketplace/sub-contractor-bids',
    element: <MarketplacePage />,
    state: 'subcontractorbidsview',
    users: ['Vendor'],
    roles: ['Admin'],
  },
  {
    path: '/marketplace/not-interested-jobs',
    element: <MarketplacePage />,
    state: 'notinterestedjobsView',
    users: ['Vendor'],
    roles: ['Admin'],
  },
  {
    path: '/marketplace/awarded-jobs',
    element: <MarketplacePage />,
    state: 'awardedJobsView',
    users: ['Vendor'],
    roles: ['Admin'],
  },
  {
    path: '/marketplace/submitted-bids',
    element: <MarketplacePage />,
    state: 'awardedJobsView',
    users: ['Vendor'],
    roles: ['Admin'],
  },
  {
    path: '/list-view',
    element: <JobOverviewListView />,
    state: 'jobsListView',
    users: ['Customer'],
    roles: ['Admin'],
  },
  {
    path: '/task-review',
    element: <TasksReview />,
    state: 'tasksReview',
    users: ['Customer'],
    roles: ['Admin'],
  },
  {
    path: '/job-detail',
    element: <JobDetailsOverview />,
    state: 'jobDetailsOverview',
    users: ['Customer', 'Vendor'],
    roles: ['Admin'],
  },
  {
    path: '/job-detail/overview',
    element: <JobDetailsOverview />,
    state: 'jobDetailsOverview',
    users: ['Customer', 'Vendor'],
    roles: ['Admin'],
  },
  {
    path: '/job-detail/worktasks',
    element: <JobDetailsOverview />,
    state: 'jobDetailsOverview',
    users: ['Customer', 'Vendor'],
    roles: ['Admin'],
  },
  {
    path: '/job-detail/actionItems',
    element: <JobDetailsOverview />,
    state: 'jobDetailsOverview',
    users: ['Customer', 'Vendor'],
    roles: ['Admin'],
  },
  {
    path: '/job-detail/associateOnTheJob',
    element: <JobDetailsOverview />,
    state: 'jobDetailsOverview',
    users: ['Customer', 'Vendor'],
    roles: ['Admin'],
  },

  {
    path: '/jobs-issues-raised',
    element: <JobsIssuesRaised />,
    state: 'jobsIssuesRaised',
    users: ['Customer', 'Vendor'],
    roles: ['Admin'],
  },

  {
    path: '/create-bid',
    element: <CreateBid />,
    state: 'createbid',
    users: ['Vendor'],
    roles: ['Supervisor'],
  },
  {
    path: '/bid-review',
    element: <BidReviewPage />,
    state: 'bidreview',
    users: ['Customer', 'Vendor'],
    roles: ['Admin'],
  },
  {
    path: '/vendor-jobs/self-performed-jobs',
    element: <VendorJobs />,
    state: 'vendorJobs',
    users: ['Vendor'],
    roles: ['Supervisor'],
    sidebarProps: {
      displayText: 'homePage:jobs',
      icon: <img src={Icons.JobsIcon} alt="Home Icon" style={{ width: '1.5rem', height: '1.5rem' }} />,
    },
  },
  {
    path: '/vendor-jobs/self-performed-jobs',
    element: <VendorJobs />,
    state: 'myjobsview',
    users: ['Vendor'],
    roles: ['Supervisor'],
  },
  {
    path: '/vendor-jobs/sub-contractor-jobs',
    element: <VendorJobs />,
    state: 'subcontractorjobsview',
    users: ['Vendor'],
    roles: ['Supervisor'],
  },
  {
    path: '/vendor-jobs/ongoing',
    element: <VendorJobs />,
    state: 'ongoing-jobs',
    users: ['Vendor'],
    roles: [],
  },
  {
    path: '/addusers',
    element: <AddUserPage />,
    state: 'addUser',
    users: ['Customer', 'Vendor'],
    roles: ['Admin'],
  },
  {
    path: '/view-facility-details',
    element: <ViewFacilityDetails />,
    state: 'viewFacilityDetails',
    users: ['Customer', 'Vendor'],
    roles: ['Admin'],
  },
  {
    path: '/add-facility',
    element: <AddFacilityPage />,
    state: 'addFacility',
    users: ['Customer', 'Vendor'],
    roles: ['Admin'],
  },
  {
    path: '/add-access-facility',
    element: <AddAccessFacilityInfo />,
    state: 'addAccessFacilityInfo',
    users: ['Customer'],
    roles: ['Admin'],
  },
  {
    path: '/add-facility-details',
    element: <AddFacilityDetails />,
    state: 'addFacilityDetails',
    users: ['Customer'],
    roles: ['Admin'],
  },
  // {
  //   path: '/Analytics',
  //   element: <Analytics />,
  //   state: 'analytics',
  //   users: ['Customer'],
  //   roles: ['Admin'],
  //   sidebarProps: {
  //     displayText: 'Analytics',
  //     icon: <LeaderboardOutlinedIcon />,
  //   },
  // },
  // {
  //   path: '/Analytics/quality-and-inspection',
  //   element: <Analytics />,
  //   state: 'quality-and-inspection',
  //   users: ['Customer'],
  //   roles: ['Admin'],
  // },
  // {
  //   path: '/Analytics/job-performance',
  //   element: <Analytics />,
  //   state: 'job-performance',
  //   users: ['Customer'],
  //   roles: ['Admin'],
  // },
];

export default appRoutes;
