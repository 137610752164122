/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { withTranslation } from 'react-i18next';
import { useCallback, useState } from 'react';
import { Stack, TextField, InputAdornment, Link } from '@mui/material';
import { Auth } from '@aws-amplify/auth';
import CircularProgress from '@mui/material/CircularProgress';
import { Controller, useForm } from 'react-hook-form';

import MasterDataApiService from '../../../../Shared/Services/MasterDataService';
import { Patterns } from '../../../../Shared/Constants';
import { useAuth } from '../../../../Configuration/AuthContext';
import { buttonStyles, EmailContainer, ForgotPasswordText, InputWrapper, ErrorMsg } from './LoginPage.Style';
import { UserType } from '../../../../Shared/Constants/App';
import { Icons } from '../../../../Shared/Constants/Icons';

import Button, { ButtonType } from '../../../../Shared/Components/Common/Buttons/Button';
import SnackbarMessage from '../../../../Shared/Components/Common/SnackbarMessage/SnackbarMessage';

function LoginWithEmail({ t, onForgotPasswordClick }) {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const { textField } = buttonStyles;

  const [isLoginProgress, setLoginProgress] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { handleSubmit, control, setError } = useForm({
    defaultValues: {
      emailId: '',
      password: '',
    },
  });
  const { user, setUser } = useAuth();

  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSignIn = useCallback(async (details) => {
    try {
      setLoginProgress(true);
      const authResponse = await Auth.signIn(details.emailId, details.password);
      const userType = authResponse.attributes['custom:Role'];

      if (userType === UserType.Customer || userType === UserType.Vendor) {
        setSnackbarMessage('Login successful');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
        localStorage.setItem('auth', JSON.stringify({ ...authResponse, signedInTime: new Date().getTime() }));
        const customer = await MasterDataApiService.getOrgDetailsByUserName();

        const loggedInUser = await MasterDataApiService.getCurrentUserInfo();
        if (loggedInUser) {
          setUser?.({ ...user, ...loggedInUser });
        }
        if (customer.data && !customer.errors?.length) {
          localStorage.setItem('customerDetails', JSON.stringify(customer.data));
        }
      } else {
        Auth.signOut();
        throw {
          message: `${t('messages:accessDenied')}`,
        };
      }
      setLoginProgress(false);
    } catch (e) {
      const message = e.message || `${t('messages:IncorrectCredential')}`;
      console.log('error', e);
      setLoginProgress(false);
      setError('emailId', { type: 'validate', message: message });
      setSnackbarMessage(message);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  }, []);

  const handleFormSubmit = async (data) => {
    if (!isLoginProgress) {
      await handleSignIn(data);
    }
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <Stack
        sx={{
          display: 'flex',
          flex: 1,
          width: '100%',
        }}>
        <EmailContainer>
          <Controller
            control={control}
            name={'emailId'}
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <InputWrapper sx={{ width: '100%' }}>
                  <TextField
                    sx={{
                      ...textField,
                    }}
                    id="outlined-required"
                    label={t('signIn:emailAddress')}
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                  />
                  <ErrorMsg>{error && error?.message}</ErrorMsg>
                </InputWrapper>
              );
            }}
            rules={{
              required: `${t('messages:emailAddressRequired')}`,
              pattern: {
                value: Patterns.Email,
                message: 'Invalid email provided.',
              },
            }}
          />
          <Controller
            control={control}
            name={'password'}
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <InputWrapper sx={{ width: '100%' }}>
                  <TextField
                    sx={{ ...textField }}
                    id="outlined-required"
                    label={t('signIn:passwordLabel')}
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    type={showPassword ? 'text' : 'password'}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {showPassword ? (
                            <img
                              src={Icons.VisibilityOnIcon}
                              alt={t('altTexts:visibilityOnIcon')}
                              onClick={handlePasswordVisibility}
                            />
                          ) : (
                            <img
                              src={Icons.VisibilityOffIcon}
                              alt={t('altTexts:visibilityOffIcon')}
                              onClick={handlePasswordVisibility}
                            />
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                  <ErrorMsg>{error && error?.message}</ErrorMsg>
                </InputWrapper>
              );
            }}
            rules={{
              required: `${t('messages:passwordIsRequired')}`,
            }}
          />
        </EmailContainer>
        <Link
          sx={{
            marginBottom: '2.5rem',
            textDecoration: 'none',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
          }}>
          <ForgotPasswordText onClick={onForgotPasswordClick}>{t('signIn:forgotPassword')}</ForgotPasswordText>
        </Link>
        <Button
          type={ButtonType.Primary}
          label={isLoginProgress ? <CircularProgress size={24} sx={{ color: 'white' }} /> : 'Login'}
          onClick={handleSubmit(handleSignIn)}
          loading={isLoginProgress}></Button>
        <SnackbarMessage
          open={snackbarOpen}
          successMessage="Login successful"
          errorMessage={snackbarMessage}
          severity={snackbarSeverity}
          onClose={() => setSnackbarOpen(false)}
        />
      </Stack>
    </form>
  );
}

export default withTranslation(['signIn', 'messages', 'altTexts'])(LoginWithEmail);
