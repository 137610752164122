/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Select, SelectChangeEvent, Box, CircularProgress } from '@mui/material';

import CustomerApiService from '../../../../Modules/Customer/Services/CustomerService';
import { getStoredCustomerDetails } from '../../../Utilities/utils';
import { BooleanType } from '../../../../API';
import { IAssociateFilter } from '../../../../Modules/Vendor/Models/AssociateFilter.model';
import { Labels } from '../GraphContainer/GraphContainer';

import { Icons } from '../../../Constants/Icons';
import theme from '../../../Themes/theme';

import { Options } from '../Dropdown/Dropdown.styles';
import { DropDown, SelectInput } from '../GraphContainer/GraphContainer.styles';
import { JobsTitle } from '../../../../Modules/Jobs/Components/IssuesRaised/JobsIssuesRaised.styles';
import { AssociateFilterField } from './AssociateFilter.Style';

const AssociateFilter: React.FC<{ onAssociateChange: (associateId: string) => void; defaultOption: string }> = ({
  onAssociateChange,
  defaultOption,
}) => {
  const [associates, setAssociates] = useState<IAssociateFilter[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedAssociate, setSelectedAssociate] = useState<string>(defaultOption);
  const [isValueOpen, setValueOpen] = useState(false);

  const { t } = useTranslation(['dashboard']);
  const toggleValue = () => {
    setValueOpen(!isValueOpen);
  };

  const handleAssociateChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value;
    setSelectedAssociate(value);
    onAssociateChange(value);
  };

  const fetchAssociates = async () => {
    setIsLoading(true);
    try {
      const customerDetails = getStoredCustomerDetails();
      const vendorId = customerDetails?.customerId;

      const response = await CustomerApiService.getAllUsers(vendorId, '', 0, 10, BooleanType.True, false);

      const formattedAssociates: IAssociateFilter[] = response.data.map(
        (associate: { userId: string; username: string }) => ({
          id: associate.userId,
          name: associate.username,
        })
      );
      setAssociates([{ id: 'All', name: 'All' }, ...formattedAssociates]);
    } catch (error) {
      console.error(t('dashboard:ErrorFetchingAssociates'));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAssociates();
  }, []);

  return (
    <AssociateFilterField>
      <JobsTitle sx={{ fontSize: theme.typography.h4?.fontSize }}>{t('dashboard:associates')}</JobsTitle>
      <DropDown>
        {isLoading ? (
          <Box>
            <CircularProgress size={30} sx={{ color: theme.palette.primary.dark }} />
          </Box>
        ) : (
          <Box onClick={toggleValue}>
            <Select
              value={selectedAssociate}
              onChange={handleAssociateChange}
              input={
                <SelectInput
                  sx={{
                    '& .MuiSelect-select': {
                      width: '6.25rem',
                    },
                  }}
                />
              }
              IconComponent={() => (
                <img
                  src={Icons.DropdownIcon}
                  style={{
                    transform: isValueOpen ? Labels.Rotate : 'none',
                    position: 'absolute',
                    right: '0.75rem',
                  }}
                />
              )}
              open={isValueOpen}
              onClose={toggleValue}
              onOpen={toggleValue}>
              {associates.map((associate) => (
                <Options key={associate.id} value={associate.id}>
                  {associate.name}
                </Options>
              ))}
            </Select>
          </Box>
        )}
      </DropDown>
    </AssociateFilterField>
  );
};

export default AssociateFilter;
