/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Menu from '@mui/material/Menu';
import { IconButton } from '@mui/material';

import { Wrapper, HeaderWrapper, Title, SelectInput, TabSelectInput, Options, DropDown } from './GraphContainer.styles';
import BarGraph from '../Graphs/BarGraph/BarGraph';
import LineGraph from '../Graphs/LineGraph/LineGraph';
import { GraphType, IData } from '../Graphs/models';
import { Menus } from '../DataGrid/DataGrid.styles';

import { Icons } from '../../../Constants/Icons';
import Download from '../../../../assets/images/download_black.svg';
import { ChipsContainer } from '../GoogleMap/GoogleMapChips.Style';
import theme from '../../../Themes/theme';
import { t } from 'i18next';

interface IGraphData {
  title: string;
  graphView?: string;
  data: IData[];
  graphColor: string;
}

interface ISimpleBarGraphProps {
  graphData: IGraphData;
  type: GraphType;
}

export enum Labels {
  NoLabel = 'Without label',
  TextDropDown = 'altTexts:dropdown',
  Rotate = 'rotate(180deg)',
  AccountMenu = 'account-menu',
  Transition = 'background-color 0.3s ease',
}

const GraphContainer = (props: ISimpleBarGraphProps): JSX.Element => {
  const [isHovered, setIsHovered] = useState(false);

  const {
    graphData: { data, title, graphColor },
    type,
  } = props;

  const [value, setValue] = useState('3M');

  const [graphData, setGraphData] = useState<IData[]>([]);

  // material UI Logic
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setIsHovered(true);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const authValue = localStorage.getItem('auth');

  const parsedValue = authValue && JSON.parse(authValue);

  const emailId: string | undefined = parsedValue?.attributes?.email;

  const dropdownData = [
    { id: 1, label: 'Last 7 days', value: '7D' },
    { id: 2, label: 'Last 15 days', value: '15D' },
    { id: 3, label: 'Last 30 days', value: '30D' },
    { id: 4, label: 'Last 3 months', value: '3M' },
  ];

  // Filtering data based on time
  const dataFiltering = useCallback(
    (value: string) => {
      let filteredData: IData[] = [];
      if (value === '7D') {
        filteredData = data?.filter((dataItem) => moment().diff(dataItem.date, 'days') <= 7);
      } else if (value === '15D') {
        filteredData = data?.filter((dataItem) => moment().diff(dataItem.date, 'days') <= 15);
      } else if (value === '30D') {
        filteredData = data?.filter((dataItem) => moment().diff(dataItem.date, 'days') <= 30);
      } else if (value === '3M') {
        filteredData = data?.filter((dataItem) => moment().diff(dataItem.date, 'months') <= 3);
      }
      setGraphData(filteredData);
    },
    [value]
  );

  const [isValueOpen, setValueOpen] = useState(false);

  const handleChange = (event: SelectChangeEvent) => {
    setValue(event.target.value);
    dataFiltering(event.target.value);
  };

  useEffect(() => {
    dataFiltering(value);
  }, []);

  const toggleValue = () => {
    setValueOpen(!isValueOpen);
  };

  const renderGraph = () => {
    if (type === GraphType.BarGraph) {
      return (
        <BarGraph
          graphData={graphData}
          graphView={props.graphData?.graphView ?? 'vertical'}
          barColor={graphColor}
          yAxisLabel={title}
        />
      );
    } else if (type === GraphType.LineGraph) {
      return <LineGraph graphData={graphData} LineColor={graphColor} />;
    }
    return null;
  };

  const menus = [
    { img: Download, title: 'Download', alt: 'download icon', link: '' },
    { img: Icons.EmailIcon, title: 'Email', alt: 'email icon', link: '' },
  ];

  const handleEmailClick = () => {
    const subject = 'Graph data';
    const body = 'This is the email body';
    if (emailId) {
      const mailtoUrl = `mailto:${encodeURIComponent(emailId)}?subject=${encodeURIComponent(
        subject
      )}&body=${encodeURIComponent(body)}`;
      window.open(mailtoUrl);
    }
  };

  const handleDownload = () => {
    // TODO: Implement download functionality
  };

  const [isButtonClicked] = useState(false);
  const backgroundColor = isButtonClicked
    ? theme.palette.primary.main
    : isHovered
      ? theme.palette.primary.main
      : theme.palette.info.main;

  const [isTablet, setIsTablet] = useState(false);

  const checkScreenSize = useCallback(() => {
    setIsTablet(window.innerWidth <= 1024);
  }, []);

  useEffect(() => {
    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);
    return () => {
      window.removeEventListener('resize', checkScreenSize);
    };
  }, [checkScreenSize]);

  const isInspectionScoreOrNoShows = title === 'Inspection Score' || title === 'No Shows';

  return (
    <Wrapper>
      {!isTablet && (
        <HeaderWrapper>
          <Title>{title}</Title>
          <DropDown>
            <div onClick={toggleValue}>
              <Select
                value={value}
                onChange={handleChange}
                defaultValue={value}
                input={<SelectInput />}
                inputProps={{ 'aria-label': Labels.NoLabel }}
                IconComponent={() => (
                  <img
                    src={Icons.DropdownIcon}
                    alt={t(Labels.TextDropDown)}
                    style={{
                      transform: isValueOpen ? Labels.Rotate : 'none',
                      position: 'absolute',
                      right: '0.75rem',
                    }}
                  />
                )}
                open={isValueOpen}
                onClose={toggleValue}
                onOpen={toggleValue}>
                {dropdownData.map((dataItem) => (
                  <Options key={dataItem.id} value={dataItem.value}>
                    {dataItem.label}
                  </Options>
                ))}
              </Select>
            </div>
            <IconButton
              onClick={handleClick}
              size="large"
              aria-controls={open ? Labels.AccountMenu : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              sx={{
                borderRadius: '0.5rem',
                backgroundColor: backgroundColor,
                padding: '0.5rem',
                marginLeft: '1rem',
                transition: Labels.Transition,
                '&:hover': {
                  backgroundColor: theme.palette.primary.main,
                },
              }}>
              <img src={Icons.MoreIcon} />
            </IconButton>

            <Menu anchorEl={anchorEl} id="account-menu" open={open} onClose={handleClose} onClick={handleClose}>
              {menus.map((dataItem, index) => {
                const { img, title, alt } = dataItem;
                return (
                  <div onClick={title === 'Email' ? handleEmailClick : handleDownload} key={index}>
                    <Menus>
                      <img src={img} alt={alt} width={24} height={24} />
                      {title}
                    </Menus>
                  </div>
                );
              })}
            </Menu>
          </DropDown>
        </HeaderWrapper>
      )}

      {isTablet && isInspectionScoreOrNoShows && (
        <HeaderWrapper sx={{ display: 'flex', flexDirection: 'column', padding: 0 }}>
          <ChipsContainer
            sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Title>{title}</Title>
            <IconButton
              onClick={handleClick}
              size="large"
              aria-controls={open ? Labels.AccountMenu : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              sx={{
                borderRadius: '0.5rem',
                backgroundColor: backgroundColor,
                padding: '0.5rem',
                transition: Labels.Transition,
                '&:hover': {
                  backgroundColor: theme.palette.primary.main,
                },
              }}>
              <img src={Icons.MoreIcon} alt={t('altTexts:more')} />
            </IconButton>
          </ChipsContainer>

          <DropDown>
            <Menu anchorEl={anchorEl} id="account-menu" open={open} onClose={handleClose} onClick={handleClose}>
              {menus.map((dataItem, index) => {
                const { img, title, alt } = dataItem;
                return (
                  <div onClick={title === 'Email' ? handleEmailClick : handleDownload} key={index}>
                    <Menus>
                      <img src={img} alt={alt} width={24} height={24} />
                      {title}
                    </Menus>
                  </div>
                );
              })}
            </Menu>
          </DropDown>

          <div onClick={toggleValue} style={{ position: 'relative' }}>
            <Select
              value={value}
              onChange={handleChange}
              defaultValue={value}
              input={<TabSelectInput />}
              inputProps={{ 'aria-label': Labels.NoLabel }}
              IconComponent={() => (
                <img
                  src={Icons.DropdownIcon}
                  alt={t(Labels.TextDropDown)}
                  style={{
                    transform: `translateY(-50%) ${isValueOpen ? Labels.Rotate : 'none'}`,
                    position: 'absolute',
                    right: '0.75rem',
                    top: '28%',
                  }}
                />
              )}
              open={isValueOpen}
              onClose={toggleValue}
              onOpen={toggleValue}>
              {dropdownData.map((dataItem) => (
                <Options key={dataItem.id} value={dataItem.value}>
                  {dataItem.label}
                </Options>
              ))}
            </Select>
          </div>
        </HeaderWrapper>
      )}

      {isTablet && !isInspectionScoreOrNoShows && (
        <HeaderWrapper>
          <Title>{title}</Title>
          <DropDown>
            <div onClick={toggleValue} style={{ position: 'relative' }}>
              <Select
                value={value}
                onChange={handleChange}
                defaultValue={value}
                input={<SelectInput />}
                inputProps={{ 'aria-label': Labels.NoLabel }}
                IconComponent={() => (
                  <img
                    src={Icons.DropdownIcon}
                    alt={t(Labels.TextDropDown)}
                    style={{
                      transform: isValueOpen ? Labels.Rotate : 'none',
                      position: 'absolute',
                      right: '0.75rem',
                    }}
                  />
                )}
                open={isValueOpen}
                onClose={toggleValue}
                onOpen={toggleValue}>
                {dropdownData.map((dataItem) => (
                  <Options key={dataItem.id} value={dataItem.value}>
                    {dataItem.label}
                  </Options>
                ))}
              </Select>
            </div>
            <IconButton
              onClick={handleClick}
              size="large"
              aria-controls={open ? Labels.AccountMenu : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              sx={{
                borderRadius: '0.5rem',
                backgroundColor: backgroundColor,
                padding: '0.5rem',
                marginLeft: '1rem',
                transition: Labels.Transition,
                '&:hover': {
                  backgroundColor: theme.palette.primary.main,
                },
              }}>
              <img src={Icons.MoreIcon} alt={t('altTexts:more')} />
            </IconButton>

            <Menu anchorEl={anchorEl} id="account-menu" open={open} onClose={handleClose} onClick={handleClose}>
              {menus.map((dataItem, index) => {
                const { img, title, alt } = dataItem;
                return (
                  <div onClick={title === 'Email' ? handleEmailClick : handleDownload} key={index}>
                    <Menus>
                      <img src={img} alt={alt} width={24} height={24} />
                      {title}
                    </Menus>
                  </div>
                );
              })}
            </Menu>
          </DropDown>
        </HeaderWrapper>
      )}

      {renderGraph()}
    </Wrapper>
  );
};

GraphContainer.defaultProps = {};

export default GraphContainer;
