/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const addTaskApproval = /* GraphQL */ `
  mutation AddTaskApproval($taskId: String!, $createTaskApproval: [CreateTaskApprovalInput]!) {
    addTaskApproval(taskId: $taskId, createTaskApproval: $createTaskApproval) {
      added {
        approvalId
        approvalName
        dueDate
        startDate
        completionDate
        reason
        status
        rankingRate
        approvalStatus
        mdApproverType
      }
      modified {
        approvalId
        approvalName
        dueDate
        startDate
        completionDate
        reason
        status
        rankingRate
        approvalStatus
        mdApproverType
      }
    }
  }
`;
export const addTaskAttachment = /* GraphQL */ `
  mutation AddTaskAttachment($taskId: String!, $createTaskAttachment: [CreateTaskAttachmentInput]!) {
    addTaskAttachment(taskId: $taskId, createTaskAttachment: $createTaskAttachment) {
      added {
        attachmentId
        attachmentStatus
        mdAttachmentType
        fileName
        mdFileContext
        fileExtension
        url
        fileHash
        comments
        createdById
        createdByName
      }
      deleted {
        attachmentId
        attachmentStatus
        mdAttachmentType
        fileName
        mdFileContext
        fileExtension
        url
        fileHash
        comments
        createdById
        createdByName
      }
    }
  }
`;
export const updateTaskAttachment = /* GraphQL */ `
  mutation UpdateTaskAttachment($taskId: String!, $updateTaskAttachmentInput: [UpdateTaskAttachmentInput]) {
    updateTaskAttachment(taskId: $taskId, updateTaskAttachmentInput: $updateTaskAttachmentInput) {
      attachmentId
      attachmentStatus
      mdAttachmentType
      fileName
      mdFileContext
      fileExtension
      url
      fileHash
      comments
      createdById
      createdByName
    }
  }
`;
export const createIssue = /* GraphQL */ `
  mutation CreateIssue($createIssueInput: CreateIssueInput) {
    createIssue(createIssueInput: $createIssueInput) {
      issueId
      title
      shortDescription
      issueRequestor
      issueResolver
      issuePriority
      templateId
      entityId
      templateValue
      attachments {
        comments
        attachmentId
        attachmentStatus
        mdAttachmentType
        fileName
        mdFileContext
        fileExtension
        url
        fileHash
        dataStatus
      }
    }
  }
`;
export const addPreShiftChecklistTemplate = /* GraphQL */ `
  mutation AddPreShiftChecklistTemplate(
    $workOrderId: String!
    $createPreShiftChecklistTemplateInput: PreShiftChecklistTemplateInput!
  ) {
    addPreShiftChecklistTemplate(
      workOrderId: $workOrderId
      createPreShiftChecklistTemplateInput: $createPreShiftChecklistTemplateInput
    ) {
      preShiftChecklistTemplateId
      checkListName
      checkListIconUrl
      mdPreShiftCheckListCategoryId
      buildingAccessConfirmation
      securityAccessConfirmation
      safetyConfirmation
      msdsChemicalSafety
      workStatus
      workOrderId
      workOrderRecSeq
      preShiftChecklistTemplateItems {
        itemName
        preShiftChecklistTemplateItemId
        mdItemActionType
        quantity
        mdQuantityType
      }
    }
  }
`;
export const updatePreShiftChecklistTemplate = /* GraphQL */ `
  mutation UpdatePreShiftChecklistTemplate(
    $preShiftChecklistTemplateId: String!
    $createPreShiftChecklistTemplateInput: PreShiftChecklistTemplateInput!
  ) {
    updatePreShiftChecklistTemplate(
      preShiftChecklistTemplateId: $preShiftChecklistTemplateId
      createPreShiftChecklistTemplateInput: $createPreShiftChecklistTemplateInput
    ) {
      preShiftChecklistTemplateId
      checkListName
      checkListIconUrl
      mdPreShiftCheckListCategoryId
      buildingAccessConfirmation
      securityAccessConfirmation
      safetyConfirmation
      msdsChemicalSafety
      workStatus
      workOrderId
      workOrderRecSeq
      updatedPreShiftChecklistTemplateItems {
        added {
          itemName
          preShiftChecklistTemplateItemId
          mdItemActionType
          quantity
          mdQuantityType
        }
        deleted {
          itemName
          preShiftChecklistTemplateItemId
          mdItemActionType
          quantity
          mdQuantityType
        }
        modified {
          itemName
          preShiftChecklistTemplateItemId
          mdItemActionType
          quantity
          mdQuantityType
        }
      }
    }
  }
`;
export const addPreShiftChecklist = /* GraphQL */ `
  mutation AddPreShiftChecklist(
    $preShiftChecklistTemplateId: String!
    $createPreShiftChecklistInput: [PreShiftChecklistInput]!
  ) {
    addPreShiftChecklist(
      preShiftChecklistTemplateId: $preShiftChecklistTemplateId
      createPreShiftChecklistInput: $createPreShiftChecklistInput
    ) {
      executionDate
      workDayId
      preShiftChecklistId
      checkListName
      checkListIconUrl
      mdPreShiftCheckListCategoryId
      preShiftChecklistTemplateId
      preShiftChecklistItems {
        itemName
        mdItemActionType
        quantity
        mdQuantityType
        workOrderServiceId
        markedAsDone
        markedBy
        markedOn
        preShiftChecklistId
        preShiftChecklistItemId
        preShiftChecklistTemplateItemId
        preShiftChecklistIssueId
      }
    }
  }
`;
export const updatePreShiftChecklist = /* GraphQL */ `
  mutation UpdatePreShiftChecklist(
    $preShiftChecklistId: String!
    $createPreShiftChecklistInput: PreShiftChecklistInput!
  ) {
    updatePreShiftChecklist(
      preShiftChecklistId: $preShiftChecklistId
      createPreShiftChecklistInput: $createPreShiftChecklistInput
    ) {
      executionDate
      workDayId
      preShiftChecklistId
      checkListName
      checkListIconUrl
      mdPreShiftCheckListCategoryId
      preShiftChecklistTemplateId
      preShiftChecklistItems {
        itemName
        mdItemActionType
        quantity
        mdQuantityType
        workOrderServiceId
        markedAsDone
        markedBy
        markedOn
        preShiftChecklistId
        preShiftChecklistItemId
        preShiftChecklistTemplateItemId
        preShiftChecklistIssueId
      }
    }
  }
`;
export const createPreShiftChecklistTemplate = /* GraphQL */ `
  mutation CreatePreShiftChecklistTemplate(
    $workOrderId: String!
    $woScheduleMasterId: String!
    $createPreShiftChecklistTemplateInput: PreShiftChecklistTemplateInput!
  ) {
    createPreShiftChecklistTemplate(
      workOrderId: $workOrderId
      woScheduleMasterId: $woScheduleMasterId
      createPreShiftChecklistTemplateInput: $createPreShiftChecklistTemplateInput
    ) {
      preShiftChecklistTemplateId
      woScheduleMasterId
      checkListName
      checkListIconUrl
      mdPreShiftCheckListCategoryId
      buildingAccessConfirmation
      securityAccessConfirmation
      safetyConfirmation
      msdsChemicalSafety
      workStatus
      workOrderId
      workOrderRecSeq
      preShiftChecklistTemplateItems {
        itemName
        preShiftChecklistTemplateItemId
        mdItemActionType
        quantity
        mdQuantityType
      }
    }
  }
`;
export const updateShiftDetails = /* GraphQL */ `
  mutation UpdateShiftDetails($updateShiftDetailsInput: [UpdateShiftDetailsInput]) {
    updateShiftDetails(updateShiftDetailsInput: $updateShiftDetailsInput) {
      shiftDetailId
      startTime
      endTime
      workOrderId
      punchInGraceMinutes
      punchOutGraceMinutes
      scheduleName
      woScheduleMasterId
      scheduleStartDate
      scheduleEndDate
      endDateOffset
    }
  }
`;
export const addPunchCard = /* GraphQL */ `
  mutation AddPunchCard($workDayId: String!, $workOrderId: String!, $createPunchCard: PunchCardInput) {
    addPunchCard(workDayId: $workDayId, workOrderId: $workOrderId, createPunchCard: $createPunchCard) {
      punchCardId
      workDayId
      userId
      punchTime
      punchType
      locationLatitude
      locationLongitude
      punchComments
      status
      errorCode
      punchNotifications
      punchMealPeriodAttestation
      punchWorkersCompConfirmation
      createdBy
      modifiedBy
      punchInTime
      punchOutTime
      totalWorkMinutes
      totalMinutesOnSite
      facilityDistance
    }
  }
`;
export const addPunchInOut = /* GraphQL */ `
  mutation AddPunchInOut($workDayId: String!, $createPunchCard: PunchCardInput) {
    addPunchInOut(workDayId: $workDayId, createPunchCard: $createPunchCard) {
      punchCardId
      workDayId
      userId
      punchTime
      punchType
      locationLatitude
      locationLongitude
      punchComments
      status
      errorCode
      punchNotifications
      punchMealPeriodAttestation
      punchWorkersCompConfirmation
      createdBy
      modifiedBy
      punchInTime
      punchOutTime
      totalWorkMinutes
      totalMinutesOnSite
      facilityDistance
    }
  }
`;
export const addPunchInOutBySupervisor = /* GraphQL */ `
  mutation AddPunchInOutBySupervisor($workDayId: String!, $createPunchCard: [PunchCardInput]!) {
    addPunchInOutBySupervisor(workDayId: $workDayId, createPunchCard: $createPunchCard) {
      punchCardId
      workDayId
      userId
      punchTime
      punchType
      locationLatitude
      locationLongitude
      punchComments
      status
      errorCode
      punchNotifications
      punchMealPeriodAttestation
      punchWorkersCompConfirmation
      createdBy
      modifiedBy
      punchInTime
      punchOutTime
      totalWorkMinutes
      totalMinutesOnSite
      facilityDistance
    }
  }
`;
export const updatePunchInOut = /* GraphQL */ `
  mutation UpdatePunchInOut($updatePunchCard: [UpdatePunchCardInput]) {
    updatePunchInOut(updatePunchCard: $updatePunchCard) {
      punchCardId
      workDayId
      userId
      punchTime
      punchType
      locationLatitude
      locationLongitude
      punchComments
      status
      errorCode
      punchNotifications
      punchMealPeriodAttestation
      punchWorkersCompConfirmation
      createdBy
      modifiedBy
      punchInTime
      punchOutTime
      totalWorkMinutes
      totalMinutesOnSite
      facilityDistance
    }
  }
`;
export const removeOrUpdateNoShowPunchCard = /* GraphQL */ `
  mutation RemoveOrUpdateNoShowPunchCard(
    $workDayId: String!
    $removeOrUpdatePunchCardInput: [RemoveOrUpdatePunchCardInput]
  ) {
    removeOrUpdateNoShowPunchCard(workDayId: $workDayId, removeOrUpdatePunchCardInput: $removeOrUpdatePunchCardInput) {
      punchCardId
      workDayId
      userId
      punchTime
      punchType
      locationLatitude
      locationLongitude
      punchComments
      status
      errorCode
      punchNotifications
      punchMealPeriodAttestation
      punchWorkersCompConfirmation
      createdBy
      modifiedBy
      punchInTime
      punchOutTime
      totalWorkMinutes
      totalMinutesOnSite
      facilityDistance
    }
  }
`;
export const addShiftBreak = /* GraphQL */ `
  mutation AddShiftBreak($workDayId: String!, $createShiftBreak: ShiftBreakInput!) {
    addShiftBreak(workDayId: $workDayId, createShiftBreak: $createShiftBreak) {
      recordId
      punchType
      facilityId
      userId
      startTime
      endTime
      comments
    }
  }
`;
export const updateShiftBreak = /* GraphQL */ `
  mutation UpdateShiftBreak($workDayId: String!, $updateShiftBreak: ShiftBreakInput!) {
    updateShiftBreak(workDayId: $workDayId, updateShiftBreak: $updateShiftBreak) {
      recordId
      punchType
      facilityId
      userId
      startTime
      endTime
      comments
    }
  }
`;
export const addShiftDetails = /* GraphQL */ `
  mutation AddShiftDetails($workOrderId: String!, $createShiftDetailsInput: [ShiftDetailsInput]!) {
    addShiftDetails(workOrderId: $workOrderId, createShiftDetailsInput: $createShiftDetailsInput) {
      added {
        shiftDetailId
        startTime
        endTime
        workOrderId
        punchInGraceMinutes
        punchOutGraceMinutes
        scheduleName
      }
      deleted {
        shiftDetailId
        startTime
        endTime
        workOrderId
        punchInGraceMinutes
        punchOutGraceMinutes
        scheduleName
      }
      modified {
        shiftDetailId
        startTime
        endTime
        workOrderId
        punchInGraceMinutes
        punchOutGraceMinutes
        scheduleName
      }
    }
  }
`;
export const generateTaskWithAssignments = /* GraphQL */ `
  mutation GenerateTaskWithAssignments($generateTasksInput: GenerateTaskInput) {
    generateTaskWithAssignments(generateTasksInput: $generateTasksInput) {
      errorCode
    }
  }
`;
export const updateMultipleTask = /* GraphQL */ `
  mutation UpdateMultipleTask($updateTaskInput: [UpdateTaskInput!]) {
    updateMultipleTask(updateTaskInput: $updateTaskInput) {
      taskId
      occurrenceDate
      suggestedReworkDate
      suggestedScheduleType
      taskNumber
      markedAsDone
      markedBy
      markedOn
      taskStatus
      workOrderId
      workDayId
      taskScheduleId
      mdServiceDescription
      plannedStartTime
      plannedEndTime
      plannedEffort
      actualEffort
      actualStartTime
      actualEndTime
      associateId
      taskSchedule {
        taskScheduleId
        mdShiftType
        isProofOfCompletionRequired
        schedule
        duration
        mdTaskId
        noOfOccurrences
        noOfOccurrencesCompleted
      }
      taskAssignment {
        taskId
        taskAssignmentId
        assignedBy
        assignedOn
        associateId
      }
      reworkTaskId
      errorCode
    }
  }
`;
export const updateTask = /* GraphQL */ `
  mutation UpdateTask($taskId: String!, $updateTaskInput: UpdateTaskInput!) {
    updateTask(taskId: $taskId, updateTaskInput: $updateTaskInput) {
      taskId
      occurrenceDate
      suggestedReworkDate
      suggestedScheduleType
      taskNumber
      markedAsDone
      markedBy
      markedOn
      taskStatus
      workOrderId
      workDayId
      taskScheduleId
      mdServiceDescription
      plannedStartTime
      plannedEndTime
      plannedEffort
      actualEffort
      actualStartTime
      actualEndTime
      associateId
      taskSchedule {
        taskScheduleId
        mdShiftType
        isProofOfCompletionRequired
        schedule
        duration
        mdTaskId
        noOfOccurrences
        noOfOccurrencesCompleted
      }
      taskAssignment {
        taskId
        taskAssignmentId
        assignedBy
        assignedOn
        associateId
      }
      reworkTaskId
      errorCode
    }
  }
`;
export const updateTaskAssignment = /* GraphQL */ `
  mutation UpdateTaskAssignment($taskAssignmentInput: AssignTaskInput) {
    updateTaskAssignment(taskAssignmentInput: $taskAssignmentInput) {
      associateId
      assignedBy
      assignedOn
      taskId
    }
  }
`;
export const updateTaskSchedule = /* GraphQL */ `
  mutation UpdateTaskSchedule($updateTaskScheduleInput: [UpdateTaskScheduleInput]!) {
    updateTaskSchedule(updateTaskScheduleInput: $updateTaskScheduleInput) {
      taskScheduleId
      mdShiftType
      isProofOfCompletionRequired
      schedule
      duration
      mdTaskId
      noOfOccurrences
      noOfOccurrencesCompleted
    }
  }
`;
export const addJobAcceptance = /* GraphQL */ `
  mutation AddJobAcceptance($teamId: String!, $workOrderId: String!, $assignTeamInput: AssignTeamInput!) {
    addJobAcceptance(teamId: $teamId, workOrderId: $workOrderId, assignTeamInput: $assignTeamInput) {
      teamId
      vendorId
      userId
      roleId
      isSupervisor
      workOrderId
      jobAccepted
      associateStartDate
      associateEndDate
      woScheduleMasterId
    }
  }
`;
export const addWorkOrderTeam = /* GraphQL */ `
  mutation AddWorkOrderTeam($workOrderId: String!, $createWorkOrderTeamInput: [CreateWorkOrderTeam]!) {
    addWorkOrderTeam(workOrderId: $workOrderId, createWorkOrderTeamInput: $createWorkOrderTeamInput) {
      added {
        teamId
        vendorId
        userId
        roleId
        isSupervisor
        workOrderId
        jobAccepted
        associateStartDate
        associateEndDate
        woScheduleMasterId
      }
      deleted {
        teamId
        vendorId
        userId
        roleId
        isSupervisor
        workOrderId
        jobAccepted
        associateStartDate
        associateEndDate
        woScheduleMasterId
      }
      reactivated {
        teamId
        vendorId
        userId
        roleId
        isSupervisor
        workOrderId
        jobAccepted
        associateStartDate
        associateEndDate
        woScheduleMasterId
      }
      modified {
        teamId
        vendorId
        userId
        roleId
        isSupervisor
        workOrderId
        jobAccepted
        associateStartDate
        associateEndDate
        woScheduleMasterId
      }
    }
  }
`;
export const addScheduleAssignments = /* GraphQL */ `
  mutation AddScheduleAssignments(
    $workOrderId: String!
    $woScheduleMasterId: String!
    $createScheduleAssignmentsInput: [CreateScheduleAssignments]
  ) {
    addScheduleAssignments(
      workOrderId: $workOrderId
      woScheduleMasterId: $woScheduleMasterId
      createScheduleAssignmentsInput: $createScheduleAssignmentsInput
    ) {
      teamId
      vendorId
      userId
      roleId
      isSupervisor
      workOrderId
      jobAccepted
      associateStartDate
      associateEndDate
      woScheduleMasterId
    }
  }
`;
export const deleteScheduleAssignments = /* GraphQL */ `
  mutation DeleteScheduleAssignments($workOrderId: String!, $woScheduleMasterId: String!, $userIds: [String]!) {
    deleteScheduleAssignments(workOrderId: $workOrderId, woScheduleMasterId: $woScheduleMasterId, userIds: $userIds)
  }
`;
export const updateScheduleAssignments = /* GraphQL */ `
  mutation UpdateScheduleAssignments(
    $workOrderId: String!
    $updateScheduleAssignmentsInput: [UpdateScheduleAssignments]
  ) {
    updateScheduleAssignments(
      workOrderId: $workOrderId
      updateScheduleAssignmentsInput: $updateScheduleAssignmentsInput
    ) {
      teamId
      vendorId
      userId
      roleId
      isSupervisor
      workOrderId
      jobAccepted
      associateStartDate
      associateEndDate
      woScheduleMasterId
    }
  }
`;
export const removeAssociateFromWorkOrderTeam = /* GraphQL */ `
  mutation RemoveAssociateFromWorkOrderTeam($removeAssociateInput: RemoveAssociateInput) {
    removeAssociateFromWorkOrderTeam(removeAssociateInput: $removeAssociateInput) {
      userId
      comments
    }
  }
`;
export const updateWorkOrderTeam = /* GraphQL */ `
  mutation UpdateWorkOrderTeam($workOrderId: String!, $updateWorkOrderTeamInput: [UpdateWorkOrderTeam]) {
    updateWorkOrderTeam(workOrderId: $workOrderId, updateWorkOrderTeamInput: $updateWorkOrderTeamInput) {
      teamId
      vendorId
      userId
      roleId
      isSupervisor
      workOrderId
      jobAccepted
      associateStartDate
      associateEndDate
      woScheduleMasterId
    }
  }
`;
export const rescheduleWorkDays = /* GraphQL */ `
  mutation RescheduleWorkDays($workOrderId: String!, $rescheduleWorkDayInput: [RescheduleWorkDayInput!]) {
    rescheduleWorkDays(workOrderId: $workOrderId, rescheduleWorkDayInput: $rescheduleWorkDayInput) {
      rescheduleSuccess {
        facilityId
        workDayId
        workWeeks
        proofOfCompletion
        timeSheets
        workDate
        geolocation
        geofencing
        mandatoryTimeOfPresence
        calculatedTimeToComplete
        actualTimeToComplete
        routeTracking
        taskRemindersAndAlerts
        issuePrepaidCard
        communication
        reworkTasks
        ratings
        mdRatingTypes
        preShiftChecklistId
        workOrderId
      }
      rescheduleFailure {
        workDayId
        newDate
        errorCode
      }
    }
  }
`;
export const addWorkOrderFacilityAccess = /* GraphQL */ `
  mutation AddWorkOrderFacilityAccess(
    $workOrderId: String!
    $createWorkOrderFacilityAccessInput: CreateWorkOrderFacilityAccess!
  ) {
    addWorkOrderFacilityAccess(
      workOrderId: $workOrderId
      createWorkOrderFacilityAccessInput: $createWorkOrderFacilityAccessInput
    ) {
      facilityId
      facilityName
      facilityAddress
      accessContactPerson
      accessContactDetails
      otherInformation
      accessInfo
      accessCode
      day
      accessFrom
      accessTo
      from
      to
      workOrderFacilityAccessId
    }
  }
`;
export const deleteWorkOrders = /* GraphQL */ `
  mutation DeleteWorkOrders($deleteWorkOrdersInput: DeleteWorkOrderInput!) {
    deleteWorkOrders(deleteWorkOrdersInput: $deleteWorkOrdersInput)
  }
`;
export const updateWorkOrderStatus = /* GraphQL */ `
  mutation UpdateWorkOrderStatus($updateWorkOrderInput: UpdateWorkOrderInput!) {
    updateWorkOrderStatus(updateWorkOrderInput: $updateWorkOrderInput) {
      workOrderId
      jobId
      bidId
      vendorId
      customerId
      name
      status
      totalAmount
      createdBy
      createdOn
      modifiedBy
      modifiedOn
      jobStartDate
      jobType
      jobEndDate
      actualStartDate
      actualEndDate
      jobCustodian
      jobOrderAcceptedOn
      jobOrderAcceptedBy
      jobOrderFilePath
      paymentMethod
      paymentFrequency
      paymentDay
      facilities {
        facilityId
        facilityName
        facilityAddress
        accessContactPerson
        accessContactDetails
        otherInformation
        accessInfo
        accessCode
        day
        accessFrom
        accessTo
        from
        to
        workOrderFacilityAccessId
      }
      services {
        workOrderServiceId
        mdServiceId
        serviceMeasure
        mdServiceUnits
        taskSchedule {
          taskScheduleId
          mdTaskId
          isProofOfCompletionRequired
        }
      }
      jobBidSummary {
        jobBidSummaryId
        jobDetails
        bidDetails
      }
      workOrderTeams {
        teamId
        vendorId
        userId
        roleId
        isSupervisor
        workOrderId
        jobAccepted
        associateStartDate
        associateEndDate
        woScheduleMasterId
      }
      shiftDetails {
        shiftDetailId
        startTime
        endTime
        punchInGraceMinutes
        punchOutGraceMinutes
      }
      subWorkOrders {
        workOrderId
        jobId
        bidId
        vendorId
        customerId
        name
        status
        totalAmount
        createdBy
        createdOn
        modifiedBy
        modifiedOn
        jobStartDate
        jobType
        jobEndDate
        actualStartDate
        actualEndDate
        jobCustodian
        jobOrderAcceptedOn
        jobOrderAcceptedBy
        jobOrderFilePath
        paymentMethod
        paymentFrequency
        paymentDay
        hasChild
        parentWorkOrderId
        services {
          workOrderServiceId
          mdServiceId
          serviceMeasure
          mdServiceUnits
          taskSchedule {
            taskScheduleId
            mdTaskId
            isProofOfCompletionRequired
          }
        }
        shiftDetails {
          shiftDetailId
          startTime
          endTime
          punchInGraceMinutes
          punchOutGraceMinutes
        }
        jobBidSummary {
          jobBidSummaryId
          jobDetails
          bidDetails
        }
        bidApprovalStatus
      }
      hasChild
      parentWorkOrderId
      jobCreator
      bidApprovalStatus
      allowVendorToReschedule
      isPaid
      workOrderRating
      isInspectionRequiredForParentJob
    }
  }
`;
export const deleteTaskAttachments = /* GraphQL */ `
  mutation DeleteTaskAttachments($deleteTaskAttachmentInput: DeleteTaskAttachmentInput!) {
    deleteTaskAttachments(deleteTaskAttachmentInput: $deleteTaskAttachmentInput)
  }
`;
export const updateWorkDay = /* GraphQL */ `
  mutation UpdateWorkDay($workDayId: String, $updateWorkDayInput: UpdateWorkDayInput) {
    updateWorkDay(workDayId: $workDayId, updateWorkDayInput: $updateWorkDayInput) {
      isReworkDay
      isShiftRescheduled
      facilityId
      workDayId
      workWeeks
      proofOfCompletion
      timeSheets
      workDate
      geolocation
      geofencing
      mandatoryTimeOfPresence
      calculatedTimeToComplete
      actualTimeToComplete
      routeTracking
      taskRemindersAndAlerts
      issuePrepaidCard
      communication
      reworkTasks
      ratings
      mdRatingTypes
      preShiftChecklistId
      workOrderId
      startTime
      endTime
    }
  }
`;
export const markNoShow = /* GraphQL */ `
  mutation MarkNoShow($markNoShowInput: MarkNoShowInput!) {
    markNoShow(markNoShowInput: $markNoShowInput) {
      workOrderId
      userId
      workOrderScheduleId
      noShowReason
      noShowDescription
      notifiedEarlier
      markedBySupervisor
      dataStatus
      recordId
    }
  }
`;
export const createAssociateProfile = /* GraphQL */ `
  mutation CreateAssociateProfile($associateProfile: AssociateProfileInput, $associateId: String) {
    createAssociateProfile(associateProfile: $associateProfile, associateId: $associateId) {
      associateProfileRecId
      associateId
      cognitoUserId
      title
      linkedInURL
      dateOfBirth
      name
      emergencyContactName
      emergencyContactNumber
      eeoInfo
      primaryLanguage
      languagesSpoken
      source
      logoUrl
      emailId
    }
  }
`;
export const updateAssociateProfile = /* GraphQL */ `
  mutation UpdateAssociateProfile($associateProfile: AssociateProfileUpdate, $associateId: String) {
    updateAssociateProfile(associateProfile: $associateProfile, associateId: $associateId) {
      associateProfileRecId
      associateId
      cognitoUserId
      title
      linkedInURL
      dateOfBirth
      name
      emergencyContactName
      emergencyContactNumber
      eeoInfo
      primaryLanguage
      languagesSpoken
      source
      logoUrl
      emailId
    }
  }
`;
export const createAssociateAddress = /* GraphQL */ `
  mutation CreateAssociateAddress($associateAddress: AssociateAddressInput, $associateId: String) {
    createAssociateAddress(associateAddress: $associateAddress, associateId: $associateId) {
      addressId
      associateId
      addressName
      addressLine1
      addressLine2
      addressLine3
      city
      stateCode
      county
      postalCode
      mdCountryCode
      landmark
      googlePlaceId
      latitude
      longitude
      regionId
    }
  }
`;
export const updateAssociateAddress = /* GraphQL */ `
  mutation UpdateAssociateAddress($associateAddress: AssociateAddressUpdate, $associateId: String) {
    updateAssociateAddress(associateAddress: $associateAddress, associateId: $associateId) {
      addressId
      associateId
      addressName
      addressLine1
      addressLine2
      addressLine3
      city
      stateCode
      county
      postalCode
      mdCountryCode
      landmark
      googlePlaceId
      latitude
      longitude
      regionId
    }
  }
`;
export const updateAssociateServiceLocation = /* GraphQL */ `
  mutation UpdateAssociateServiceLocation(
    $associateServiceLocationInput: [AssociateServiceLocationInput]
    $associateId: String
  ) {
    updateAssociateServiceLocation(
      associateServiceLocationInput: $associateServiceLocationInput
      associateId: $associateId
    ) {
      serviceLocationsDeleted {
        associateServiceLocationId
        associateId
        mdAreaName
        mdAreaType
        mdAreaCategory
        locationId
        country
      }
      serviceLocationsAdded {
        associateServiceLocationId
        associateId
        mdAreaName
        mdAreaType
        mdAreaCategory
        locationId
        country
      }
      serviceLocationsReactivated {
        associateServiceLocationId
        associateId
        mdAreaName
        mdAreaType
        mdAreaCategory
        locationId
        country
      }
    }
  }
`;
export const updateAssociateShiftPreferences = /* GraphQL */ `
  mutation UpdateAssociateShiftPreferences(
    $associateShiftPreferencesInput: [AssociateShiftPreferenceInput]
    $associateId: String
  ) {
    updateAssociateShiftPreferences(
      associateShiftPreferencesInput: $associateShiftPreferencesInput
      associateId: $associateId
    ) {
      shiftPreferenceId
      day
      fromTime
      toTime
      isFlexibleShiftPreference
    }
  }
`;
export const updateAssociateSkills = /* GraphQL */ `
  mutation UpdateAssociateSkills($associateSkillsInput: [AssociateSkillsInput], $associateId: String) {
    updateAssociateSkills(associateSkillsInput: $associateSkillsInput, associateId: $associateId) {
      added
      deleted
    }
  }
`;
export const addAddress = /* GraphQL */ `
  mutation AddAddress($addressInput: AddressInput!) {
    addAddress(addressInput: $addressInput) {
      addressId
      customerId
      addressName
      noOfBuildings
      addressLine1
      addressLine2
      addressLine3
      city
      stateCode
      county
      postalCode
      mdCountryCode
      landmark
      googlePlaceId
      latitude
      longitude
      mdMsaId
      logoUrl
      intId
    }
  }
`;
export const updateAddress = /* GraphQL */ `
  mutation UpdateAddress($addressData: AddressData!) {
    updateAddress(addressData: $addressData) {
      addressId
      customerId
      addressName
      noOfBuildings
      addressLine1
      addressLine2
      addressLine3
      city
      stateCode
      county
      postalCode
      mdCountryCode
      landmark
      googlePlaceId
      latitude
      longitude
      mdMsaId
      logoUrl
      intId
    }
  }
`;
export const addCustomer = /* GraphQL */ `
  mutation AddCustomer($customerInput: CustomerInput!) {
    addCustomer(customerInput: $customerInput) {
      customerId
      legalName
      doingBusinessAs
      websiteUrl
      federalEmployerId
      SICCode
      taxId
      logo
    }
  }
`;
export const updateCustomer = /* GraphQL */ `
  mutation UpdateCustomer($customerId: String!, $customerData: CustomerData!) {
    updateCustomer(customerId: $customerId, customerData: $customerData) {
      customerId
      legalName
      doingBusinessAs
      websiteUrl
      federalEmployerId
      SICCode
      taxId
      logo
    }
  }
`;
export const addCustomerDetails = /* GraphQL */ `
  mutation AddCustomerDetails($customerDetailsInput: CustomerDetailsInput!) {
    addCustomerDetails(customerDetailsInput: $customerDetailsInput) {
      customerDetailsId
      customerId
      noOfEmployees
      annualRevenueEstimate
      mdCustomerType
      referralSource
      phone
      fax
      email
    }
  }
`;
export const updateCustomerDetails = /* GraphQL */ `
  mutation UpdateCustomerDetails($customerDetailsData: CustomerDetailsData!) {
    updateCustomerDetails(customerDetailsData: $customerDetailsData) {
      customerDetailsId
      customerId
      noOfEmployees
      annualRevenueEstimate
      mdCustomerType
      referralSource
      phone
      fax
      email
    }
  }
`;
export const addFacility = /* GraphQL */ `
  mutation AddFacility($facilityInput: FacilityInput!) {
    addFacility(facilityInput: $facilityInput) {
      callHours
      transportationName
      storageName
      callStartTime
      callEndTime
      facilityId
      customerId
      buildingName
      buildingMakeup
      noOfFloors
      sqFootage
      mdFacilityType
      serviceableSqFootage
      occupancy
      supplyStorageAvailability
      transportRequiredWithinLocation
      contactId
      facilityTrafficLevel
      emergencyContactName
      emergencyContactPhone
      cleaningInstructions
      geoFenceRadius
      addressId
      address {
        addressId
        customerId
        addressName
        noOfBuildings
        addressLine1
        addressLine2
        addressLine3
        city
        stateCode
        county
        postalCode
        mdCountryCode
        landmark
        googlePlaceId
        latitude
        longitude
        mdMsaId
        logoUrl
        intId
      }
    }
  }
`;
export const updateFacility = /* GraphQL */ `
  mutation UpdateFacility($facilityData: FacilityData!) {
    updateFacility(facilityData: $facilityData) {
      callHours
      transportationName
      storageName
      callStartTime
      callEndTime
      facilityId
      customerId
      buildingName
      buildingMakeup
      noOfFloors
      sqFootage
      mdFacilityType
      serviceableSqFootage
      occupancy
      supplyStorageAvailability
      transportRequiredWithinLocation
      contactId
      facilityTrafficLevel
      emergencyContactName
      emergencyContactPhone
      cleaningInstructions
      geoFenceRadius
      addressId
      address {
        addressId
        customerId
        addressName
        noOfBuildings
        addressLine1
        addressLine2
        addressLine3
        city
        stateCode
        county
        postalCode
        mdCountryCode
        landmark
        googlePlaceId
        latitude
        longitude
        mdMsaId
        logoUrl
        intId
      }
    }
  }
`;
export const deleteFacility = /* GraphQL */ `
  mutation DeleteFacility($deleteFacilityInput: DeleteFacilityInput!) {
    deleteFacility(deleteFacilityInput: $deleteFacilityInput)
  }
`;
export const addCustomerFacilityAccess = /* GraphQL */ `
  mutation AddCustomerFacilityAccess(
    $customerId: String!
    $facilityId: String!
    $createCustomerFacilityAccessInput: CreateFacilityAccessDetails!
  ) {
    addCustomerFacilityAccess(
      customerId: $customerId
      facilityId: $facilityId
      createCustomerFacilityAccessInput: $createCustomerFacilityAccessInput
    ) {
      facilityAccessDetailId
      facilityId
      jobId
      accessContactPerson
      roleName
      accessContactDetails
      otherInformation
      accessInfo
      accessCode
      day
      from
      to
    }
  }
`;
export const updateCustomerFacilityAccess = /* GraphQL */ `
  mutation UpdateCustomerFacilityAccess(
    $customerId: String!
    $facilityId: String!
    $updateCustomerFacilityAccessInput: UpdateFacilityAccessDetails!
  ) {
    updateCustomerFacilityAccess(
      customerId: $customerId
      facilityId: $facilityId
      updateCustomerFacilityAccessInput: $updateCustomerFacilityAccessInput
    ) {
      facilityAccessDetailId
      facilityId
      jobId
      accessContactPerson
      roleName
      accessContactDetails
      otherInformation
      accessInfo
      accessCode
      day
      from
      to
    }
  }
`;
export const addFacilityServicesDetails = /* GraphQL */ `
  mutation AddFacilityServicesDetails($facilityServicesInput: FacilityServicesInput!) {
    addFacilityServicesDetails(facilityServicesInput: $facilityServicesInput) {
      facilityDetailsId
      facilityId
      floorNumber
      mdAreaType
      mdFlooringType
      quantity
      mdUnitType
    }
  }
`;
export const updateFacilityServicesDetails = /* GraphQL */ `
  mutation UpdateFacilityServicesDetails($facilityServicesInput: UpdateFacilityServicesInput!) {
    updateFacilityServicesDetails(facilityServicesInput: $facilityServicesInput) {
      facilityDetailsId
      facilityId
      floorNumber
      mdAreaType
      mdFlooringType
      quantity
      mdUnitType
    }
  }
`;
export const addFacilityServiceSpecification = /* GraphQL */ `
  mutation AddFacilityServiceSpecification(
    $customerId: String!
    $facilityId: String!
    $facilityServiceSpecificationInput: [CreateFacilityServiceSpecificationInput]
  ) {
    addFacilityServiceSpecification(
      customerId: $customerId
      facilityId: $facilityId
      facilityServiceSpecificationInput: $facilityServiceSpecificationInput
    ) {
      added {
        facilityId
        floorNumber
        mdServiceId
        serviceQuantity
        mdServiceUnitType
      }
      deleted {
        facilityId
        floorNumber
        mdServiceId
        serviceQuantity
        mdServiceUnitType
      }
      reactivated {
        facilityId
        floorNumber
        mdServiceId
        serviceQuantity
        mdServiceUnitType
      }
      modified {
        facilityId
        floorNumber
        mdServiceId
        serviceQuantity
        mdServiceUnitType
      }
    }
  }
`;
export const addBeneficiaries = /* GraphQL */ `
  mutation AddBeneficiaries($vendorId: String, $beneficiaryInput: [BeneficiaryInput]) {
    addBeneficiaries(vendorId: $vendorId, beneficiaryInput: $beneficiaryInput) {
      dataStatus
      beneficiaryId
      name
      TIN
      percentageHolding
      addressLine1
      addressLine2
      city
      state
      zipCode
      vendorId
      modifiedOn
    }
  }
`;
export const removeBeneficiaries = /* GraphQL */ `
  mutation RemoveBeneficiaries($beneficiaryIds: [String]) {
    removeBeneficiaries(beneficiaryIds: $beneficiaryIds) {
      dataStatus
      beneficiaryId
      name
      TIN
      percentageHolding
      addressLine1
      addressLine2
      city
      state
      zipCode
      vendorId
      modifiedOn
    }
  }
`;
export const updateBeneficiaries = /* GraphQL */ `
  mutation UpdateBeneficiaries($beneficiaryUpdate: [BeneficiaryUpdate]) {
    updateBeneficiaries(beneficiaryUpdate: $beneficiaryUpdate) {
      dataStatus
      beneficiaryId
      name
      TIN
      percentageHolding
      addressLine1
      addressLine2
      city
      state
      zipCode
      vendorId
      modifiedOn
    }
  }
`;
export const addInsurances = /* GraphQL */ `
  mutation AddInsurances($vendorId: String, $insuranceInput: [InsuranceInput]) {
    addInsurances(vendorId: $vendorId, insuranceInput: $insuranceInput) {
      fileName
      insuranceName
      validTill
      producer
      insured
      contactName
      contactPhone
      address
      fax
      insuranceId
      dataStatus
      vendorId
      createdOn
    }
  }
`;
export const updateInsurances = /* GraphQL */ `
  mutation UpdateInsurances($insuranceUpdate: [InsuranceUpdate]) {
    updateInsurances(insuranceUpdate: $insuranceUpdate) {
      fileName
      insuranceName
      validTill
      producer
      insured
      contactName
      contactPhone
      address
      fax
      insuranceId
      dataStatus
      vendorId
      createdOn
    }
  }
`;
export const removeInsurances = /* GraphQL */ `
  mutation RemoveInsurances($insuranceIds: [String]) {
    removeInsurances(insuranceIds: $insuranceIds) {
      fileName
      insuranceName
      validTill
      producer
      insured
      contactName
      contactPhone
      address
      fax
      insuranceId
      dataStatus
      vendorId
      createdOn
    }
  }
`;
export const addInsuranceAttachment = /* GraphQL */ `
  mutation AddInsuranceAttachment($insuranceId: String!, $insuranceAttachmentInput: [VendorAttachmentInput]) {
    addInsuranceAttachment(insuranceId: $insuranceId, insuranceAttachmentInput: $insuranceAttachmentInput) {
      attachmentId
      refType
      refId
      fileName
      mdFileContext
      fileExtension
      url
      dataStatus
      date
      createdOn
      modifiedOn
    }
  }
`;
export const deleteInsuranceAttachment = /* GraphQL */ `
  mutation DeleteInsuranceAttachment($insuranceId: String!, $deleteInsuranceAttachmentInput: [String!]) {
    deleteInsuranceAttachment(
      insuranceId: $insuranceId
      deleteInsuranceAttachmentInput: $deleteInsuranceAttachmentInput
    ) {
      attachmentId
      refType
      refId
      fileName
      mdFileContext
      fileExtension
      url
      dataStatus
      date
      createdOn
      modifiedOn
    }
  }
`;
export const updateInsuranceAttachment = /* GraphQL */ `
  mutation UpdateInsuranceAttachment(
    $insuranceId: String!
    $updateInsuranceAttachmentInput: [UpdateVendorAttachmentInput]
  ) {
    updateInsuranceAttachment(
      insuranceId: $insuranceId
      updateInsuranceAttachmentInput: $updateInsuranceAttachmentInput
    ) {
      attachmentId
      refType
      refId
      fileName
      mdFileContext
      fileExtension
      url
      dataStatus
      date
      createdOn
      modifiedOn
    }
  }
`;
export const updateVendorAddress = /* GraphQL */ `
  mutation UpdateVendorAddress($addressId: String!, $vendorAddressUpdate: VendorAddressUpdate) {
    updateVendorAddress(addressId: $addressId, vendorAddressUpdate: $vendorAddressUpdate) {
      addressId
      vendorId
      addressName
      addressLine1
      addressLine2
      addressLine3
      city
      stateCode
      county
      postalCode
      mdCountryCode
      landmark
      googlePlaceId
      latitude
      longitude
      mdMsaId
      modifiedOn
      regionId
    }
  }
`;
export const createManyVendorAttachments = /* GraphQL */ `
  mutation CreateManyVendorAttachments($vendorId: String!, $vendorAttachmentInput: [VendorAttachmentInput!]) {
    createManyVendorAttachments(vendorId: $vendorId, vendorAttachmentInput: $vendorAttachmentInput) {
      attachmentId
      refType
      refId
      fileName
      mdFileContext
      fileExtension
      url
      dataStatus
      date
      createdOn
      modifiedOn
    }
  }
`;
export const updateManyVendorAttachments = /* GraphQL */ `
  mutation UpdateManyVendorAttachments($vendorId: String!, $vendorAttachmentInput: [VendorAttachmentInput!]) {
    updateManyVendorAttachments(vendorId: $vendorId, vendorAttachmentInput: $vendorAttachmentInput) {
      attachmentId
      refType
      refId
      fileName
      mdFileContext
      fileExtension
      url
      dataStatus
      date
      createdOn
      modifiedOn
    }
  }
`;
export const deleteManyVendorAttachments = /* GraphQL */ `
  mutation DeleteManyVendorAttachments($vendorId: String!, $attachmentIds: [String!]) {
    deleteManyVendorAttachments(vendorId: $vendorId, attachmentIds: $attachmentIds) {
      vendorAttachmentId
      vendorId
      attachmentId
      dataStatus
    }
  }
`;
export const addVendorAddress = /* GraphQL */ `
  mutation AddVendorAddress($vendorAddressInput: VendorAddressInput, $vendorId: String) {
    addVendorAddress(vendorAddressInput: $vendorAddressInput, vendorId: $vendorId) {
      addressId
      vendorId
      addressName
      addressLine1
      addressLine2
      addressLine3
      city
      stateCode
      county
      postalCode
      mdCountryCode
      landmark
      googlePlaceId
      latitude
      longitude
      mdMsaId
      modifiedOn
      regionId
    }
  }
`;
export const addVendorDetails = /* GraphQL */ `
  mutation AddVendorDetails($vendorDetailsInput: VendorDetailsInput, $vendorId: String) {
    addVendorDetails(vendorDetailsInput: $vendorDetailsInput, vendorId: $vendorId) {
      vendorId
      annualRevenueEstimate
      fax
      coi
      noOfEmployees
      workWeekDefinition
      w9FormUrl
    }
  }
`;
export const addVendorTeam = /* GraphQL */ `
  mutation AddVendorTeam($vendorTeamInput: VendorTeamInput) {
    addVendorTeam(vendorTeamInput: $vendorTeamInput) {
      vendorId
      roleId
      email
      name
      userId
      phoneNumber
    }
  }
`;
export const addVendor = /* GraphQL */ `
  mutation AddVendor($vendorInput: VendorInput!) {
    addVendor(vendorInput: $vendorInput) {
      vendorId
      vendorCompanyName
      vendorCompanyType
      doingBusinessAs
      SSID
      taxId
      websiteUrl
      logoUrl
      referralSource
      phone
      email
      dataStatus
      modifiedOn
    }
  }
`;
export const addRoleToVendorTeam = /* GraphQL */ `
  mutation AddRoleToVendorTeam($vendorRoleInput: AddVendorTeamRoleInput) {
    addRoleToVendorTeam(vendorRoleInput: $vendorRoleInput) {
      roleId
      roleName
      vendorId
    }
  }
`;
export const updateVendorDetails = /* GraphQL */ `
  mutation UpdateVendorDetails($vendorId: String!, $updateVendorDetailsInput: VendorDetailsUpdate) {
    updateVendorDetails(vendorId: $vendorId, updateVendorDetailsInput: $updateVendorDetailsInput) {
      vendorId
      annualRevenueEstimate
      fax
      coi
      noOfEmployees
      workWeekDefinition
      w9FormUrl
    }
  }
`;
export const updateVendorServiceLocation = /* GraphQL */ `
  mutation UpdateVendorServiceLocation($vendorId: String, $vendorServiceLocationInput: [VendorServiceLocationInput]) {
    updateVendorServiceLocation(vendorId: $vendorId, vendorServiceLocationInput: $vendorServiceLocationInput) {
      serviceLocationsDeleted {
        vendorServiceLocationId
        vendorId
        mdAreaName
        mdAreaType
        mdAreaCode
        mdAreaCategory
        country
        locationId
      }
      serviceLocationsAdded {
        vendorServiceLocationId
        vendorId
        mdAreaName
        mdAreaType
        mdAreaCode
        mdAreaCategory
        country
        locationId
      }
      serviceLocationsReactivated {
        vendorServiceLocationId
        vendorId
        mdAreaName
        mdAreaType
        mdAreaCode
        mdAreaCategory
        country
        locationId
      }
    }
  }
`;
export const updateVendorServicesOffered = /* GraphQL */ `
  mutation UpdateVendorServicesOffered($vendorServicesOfferedInput: [VendorServiceInput], $vendorId: String) {
    updateVendorServicesOffered(vendorServicesOfferedInput: $vendorServicesOfferedInput, vendorId: $vendorId) {
      added
      deleted
      reactivated
    }
  }
`;
export const updateVendorStatus = /* GraphQL */ `
  mutation UpdateVendorStatus($vendorProfileStatusUpdateInput: VendorProfileStatusUpdateInput!) {
    updateVendorStatus(vendorProfileStatusUpdateInput: $vendorProfileStatusUpdateInput) {
      vendorId
      vendorCompanyName
      vendorCompanyType
      doingBusinessAs
      SSID
      taxId
      websiteUrl
      logoUrl
      referralSource
      phone
      email
      dataStatus
      modifiedOn
    }
  }
`;
export const updateVendor = /* GraphQL */ `
  mutation UpdateVendor($vendorId: String!, $vendorUpdate: VendorUpdate) {
    updateVendor(vendorId: $vendorId, vendorUpdate: $vendorUpdate) {
      vendorId
      vendorCompanyName
      vendorCompanyType
      doingBusinessAs
      SSID
      taxId
      websiteUrl
      logoUrl
      referralSource
      phone
      email
      dataStatus
      modifiedOn
    }
  }
`;
export const createManyVendorAccreditation = /* GraphQL */ `
  mutation CreateManyVendorAccreditation(
    $vendorId: String!
    $createVendorAccreditationInput: [CreateVendorAccreditationInput]
  ) {
    createManyVendorAccreditation(
      vendorId: $vendorId
      createVendorAccreditationInput: $createVendorAccreditationInput
    ) {
      recordId
      recStatus
      labels
      certificateId
      accreditationType
      issuingAuthority
      effectiveFromDate
      effectiveEndDate
      accreditationSubType {
        accreditationSubTypeId
        recSeq
        recStatus
        subType
        value
        dataStatus
        vendorAccreditationId
        vendorAccreditationRecSeq
        fromDate
        endDate
        createdBy
        createdOn
        modifiedBy
        modifiedOn
      }
      msaCustomer
      type
      status
      dataStatus
      vendorId
      attachmentId
      fromDate
      endDate
      createdBy
      createdOn
      modifiedBy
      modifiedOn
    }
  }
`;
export const deleteManyVendorAccreditation = /* GraphQL */ `
  mutation DeleteManyVendorAccreditation($recordIds: [String]!) {
    deleteManyVendorAccreditation(recordIds: $recordIds) {
      recordId
      attachmentId
      certificateId
      accreditationType
      labels
      issuingAuthority
      effectiveFromDate
      effectiveEndDate
      accreditationSubType {
        accreditationSubTypeId
        recSeq
        recStatus
        subType
        value
        dataStatus
        vendorAccreditationId
        vendorAccreditationRecSeq
        fromDate
        endDate
        createdBy
        createdOn
        modifiedBy
        modifiedOn
      }
      msaCustomer
      type
      status
      dataStatus
      createdBy
      modifiedBy
      createdOn
      modifiedOn
      fromDate
      vendorId
      attachments {
        attachmentId
        refType
        refId
        fileName
        mdFileContext
        fileExtension
        url
        dataStatus
        date
        createdOn
        modifiedOn
      }
    }
  }
`;
export const addUser = /* GraphQL */ `
  mutation AddUser($userInput: UserInput!) {
    addUser(userInput: $userInput) {
      id
      name
      email
    }
  }
`;
export const deleteCognitoUser = /* GraphQL */ `
  mutation DeleteCognitoUser($userData: UserData!) {
    deleteCognitoUser(userData: $userData)
  }
`;
export const updateCognitoUser = /* GraphQL */ `
  mutation UpdateCognitoUser($userData: UserData!) {
    updateCognitoUser(userData: $userData)
  }
`;
export const addMasterData = /* GraphQL */ `
  mutation AddMasterData($masterDataInput: MasterDataInput!) {
    addMasterData(masterDataInput: $masterDataInput) {
      code
      keyCode
      value
      subKey1Value
      subKey2Value
      canCustomize
      userOrg
      processId
      defaultCode
      keyId
      keyType
      displayName
    }
  }
`;
export const addOrgMasterData = /* GraphQL */ `
  mutation AddOrgMasterData($orgMasterDataInput: OrgMasterDataInput!) {
    addOrgMasterData(orgMasterDataInput: $orgMasterDataInput) {
      keyId
      keyType
      code
      keyCode
      displayName
      value
    }
  }
`;
export const updateMasterData = /* GraphQL */ `
  mutation UpdateMasterData($updateMasterDataInput: UpdateMasterDataInput!) {
    updateMasterData(updateMasterDataInput: $updateMasterDataInput) {
      code
      keyCode
      value
      subKey1Value
      subKey2Value
      canCustomize
      userOrg
      processId
      defaultCode
      keyId
      keyType
      displayName
    }
  }
`;
export const addFeatureFlag = /* GraphQL */ `
  mutation AddFeatureFlag($featureFlagInput: FeatureFlagInput!) {
    addFeatureFlag(featureFlagInput: $featureFlagInput) {
      keyId
      keyType
      featureName
      featureKey
      description
      isMobileEnabled
      isWebEnabled
      isAdminEnabled
      createdBy
      createdOn
      modifiedBy
      modifiedOn
    }
  }
`;
export const openJobForBid = /* GraphQL */ `
  mutation OpenJobForBid($customerId: String!, $jobId: String!, $jobCustomerInput: JobCustomerInput) {
    openJobForBid(customerId: $customerId, jobId: $jobId, jobCustomerInput: $jobCustomerInput) {
      jobId
      jobName
      startDate
      stopDate
      dataStatus
      specialInstructions
      draftStep
      draftStatus
      jobType
    }
  }
`;
export const generateW9 = /* GraphQL */ `
  mutation GenerateW9($w9Input: W9Input) {
    generateW9(w9Input: $w9Input) {
      embedURL {
        embeddedSigningURL
      }
      requestId
    }
  }
`;
export const updateUserDetails = /* GraphQL */ `
  mutation UpdateUserDetails($updateUserDetailsInput: UpdateUserInput) {
    updateUserDetails(updateUserDetailsInput: $updateUserDetailsInput) {
      userId
      email
      phoneNumber
      dataStatus
      salutation
      username
    }
  }
`;
export const addUserToTeam = /* GraphQL */ `
  mutation AddUserToTeam($userId: String!, $teamId: String!) {
    addUserToTeam(userId: $userId, teamId: $teamId) {
      userId
      teamId
    }
  }
`;
export const addRoleToUser = /* GraphQL */ `
  mutation AddRoleToUser($assignRoleToUserInput: AssignRoleToUserInput) {
    addRoleToUser(assignRoleToUserInput: $assignRoleToUserInput) {
      roleId
      userId
    }
  }
`;
export const addRoleToTeam = /* GraphQL */ `
  mutation AddRoleToTeam($roleId: String!, $teamId: String!) {
    addRoleToTeam(roleId: $roleId, teamId: $teamId) {
      roleId
      teamId
    }
  }
`;
export const removeUserFromTeam = /* GraphQL */ `
  mutation RemoveUserFromTeam($userId: String!, $teamId: String!) {
    removeUserFromTeam(userId: $userId, teamId: $teamId) {
      userId
      teamId
      message
    }
  }
`;
export const createOrganization = /* GraphQL */ `
  mutation CreateOrganization($organizationInput: CreateOrganizationInput) {
    createOrganization(organizationInput: $organizationInput) {
      organizationId
      organizationName
      dataStatus
      teams {
        teamId
        name
      }
      users {
        userId
      }
      errorCode
    }
  }
`;
export const addPermissionToRole = /* GraphQL */ `
  mutation AddPermissionToRole($inputRolePermission: CreateRolePermissionInput!) {
    addPermissionToRole(inputRolePermission: $inputRolePermission) {
      permissionId
      roleId
    }
  }
`;
export const createRole = /* GraphQL */ `
  mutation CreateRole($roleInput: CreateRole!) {
    createRole(roleInput: $roleInput) {
      roleName
      roleId
      organizationId
    }
  }
`;
export const createTeam = /* GraphQL */ `
  mutation CreateTeam($teamInput: CreateTeam!) {
    createTeam(teamInput: $teamInput) {
      organizationId
      name
      teamId
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser($userInput: CreateUser!) {
    createUser(userInput: $userInput) {
      username
      organizationId
      userId
      errorCode
      salutation
    }
  }
`;
export const removeUser = /* GraphQL */ `
  mutation RemoveUser($removeUserInput: RemoveUser!) {
    removeUser(removeUserInput: $removeUserInput) {
      roleId
      featureId
    }
  }
`;
export const addServiceCategoryV3 = /* GraphQL */ `
  mutation AddServiceCategoryV3($createServiceCategory: ServiceCategoryV3Input) {
    addServiceCategoryV3(createServiceCategory: $createServiceCategory) {
      serviceCategoryId
      taskId
      mdAreaType
    }
  }
`;
export const addOrUpdateOrganizationUser = /* GraphQL */ `
  mutation AddOrUpdateOrganizationUser($addOrUpdateOrganizationUserInput: AddOrganizationUserInput) {
    addOrUpdateOrganizationUser(addOrUpdateOrganizationUserInput: $addOrUpdateOrganizationUserInput) {
      userId
      username
      salutation
      email
      phoneNumber
      dataStatus
      teamId
      organizationId
    }
  }
`;
export const createRegion = /* GraphQL */ `
  mutation CreateRegion($regionInput: RegionInput!) {
    createRegion(regionInput: $regionInput) {
      regionId
      regionName
      organizationId
      parentRegionId
    }
  }
`;
export const updateRegion = /* GraphQL */ `
  mutation UpdateRegion($regionId: String!, $regionInput: RegionInput!) {
    updateRegion(regionId: $regionId, regionInput: $regionInput) {
      regionId
      regionName
      organizationId
      parentRegionId
    }
  }
`;
export const createUserRoleScope = /* GraphQL */ `
  mutation CreateUserRoleScope($userRoleId: String!, $userRoleScopeInput: UserRoleScopeInput!) {
    createUserRoleScope(userRoleId: $userRoleId, userRoleScopeInput: $userRoleScopeInput) {
      userRoleScopeId
      keyId
      keyType
      userId
      organizationId
      roleId
      customerId
      mdCountryCode
      regionId
      subRegionId
      facilityId
      userRoleId
      keyName
      userName
      organizationName
      roleName
      customerName
      regionName
      subRegionName
      facilityName
      createdByName
      modifiedByName
      intAddressId
      jobDescription
      jobNumber
      customerNumber
    }
  }
`;
export const updateUserRoleScope = /* GraphQL */ `
  mutation UpdateUserRoleScope($userRoleScopeId: String!, $userRoleScopeInput: UserRoleScopeInput!) {
    updateUserRoleScope(userRoleScopeId: $userRoleScopeId, userRoleScopeInput: $userRoleScopeInput) {
      userRoleScopeId
      keyId
      keyType
      userId
      organizationId
      roleId
      customerId
      mdCountryCode
      regionId
      subRegionId
      facilityId
      userRoleId
      keyName
      userName
      organizationName
      roleName
      customerName
      regionName
      subRegionName
      facilityName
      createdByName
      modifiedByName
      intAddressId
      jobDescription
      jobNumber
      customerNumber
    }
  }
`;
export const deleteUserRoleScope = /* GraphQL */ `
  mutation DeleteUserRoleScope($userRoleScopeId: String!) {
    deleteUserRoleScope(userRoleScopeId: $userRoleScopeId) {
      userRoleScopeId
      keyId
      keyType
      userId
      organizationId
      roleId
      customerId
      mdCountryCode
      regionId
      subRegionId
      facilityId
      userRoleId
      keyName
      userName
      organizationName
      roleName
      customerName
      regionName
      subRegionName
      facilityName
      createdByName
      modifiedByName
      intAddressId
      jobDescription
      jobNumber
      customerNumber
    }
  }
`;
export const deleteUsers = /* GraphQL */ `
  mutation DeleteUsers($deleteUserInput: DeleteUserInput) {
    deleteUsers(deleteUserInput: $deleteUserInput) {
      userId
      username
      email
      signedUp
      phoneNumber
      dataStatus
      organizationId
      teamId
      errorCode
    }
  }
`;
export const setCognitoUserPassword = /* GraphQL */ `
  mutation SetCognitoUserPassword($userId: String, $isPermanentPassword: Boolean, $shouldNotNotifyUser: Boolean) {
    setCognitoUserPassword(
      userId: $userId
      isPermanentPassword: $isPermanentPassword
      shouldNotNotifyUser: $shouldNotNotifyUser
    )
  }
`;
export const createUserAuthEvent = /* GraphQL */ `
  mutation CreateUserAuthEvent($userAuthEventInput: UserAuthEventInput) {
    createUserAuthEvent(userAuthEventInput: $userAuthEventInput) {
      userAuthEventId
    }
  }
`;
export const createInspection = /* GraphQL */ `
  mutation CreateInspection($createInspectionInput: CreateInspectionInput!) {
    createInspection(createInspectionInput: $createInspectionInput) {
      inspectionId
      recSeq
      recStatus
      orgId
      facilityId
      jobId
      inspectorId
      inspectionName
      inspectionDescription
      inspectionEmailRecipient
      inspectionReportUrl
      frequencyType
      inspectionStartDate
      inspectionEndDate
      plannedInspectionStartDate
      plannedInspectionEndDate
      facilityName
      facilityAddress
      facilityType
      vendorName
      jobName
      intId
      latitude
      longitude
      dataStatus
      createdBy
      createdOn
      modifiedBy
      modifiedOn
    }
  }
`;
export const createInspectionFromCsv = /* GraphQL */ `
  mutation CreateInspectionFromCsv($createInspectionInput: CreateInspectionInput!) {
    createInspectionFromCsv(createInspectionInput: $createInspectionInput) {
      inspection {
        status
        data {
          inspectionId
          recSeq
          recStatus
          orgId
          facilityId
          jobId
          inspectorId
          inspectionName
          inspectionDescription
          inspectionEmailRecipient
          inspectionReportUrl
          frequencyType
          inspectionStartDate
          inspectionEndDate
          plannedInspectionStartDate
          plannedInspectionEndDate
          facilityName
          facilityAddress
          facilityType
          vendorName
          jobName
          intId
          latitude
          longitude
          dataStatus
          createdBy
          createdOn
          modifiedBy
          modifiedOn
        }
      }
      sections {
        status
        data {
          sectionId
          recSeq
          recStatus
          inspectionId
          sectionName
          sectionDescription
          displaySeq
          isProofRequired
          questions {
            questionId
            recSeq
            recStatus
            inspectionId
            sectionId
            questionText
            questionDescription
            questionNumber
            answerType
            isMandatory
            answerPattern
            parentQuestionId
            optionId
            answerOptions {
              answerId
              recSeq
              recStatus
              questionId
              displayText
              displaySeq
              answerDescription
              dataStatus
              createdBy
              createdOn
              modifiedBy
              modifiedOn
            }
            dataStatus
            createdBy
            createdOn
            modifiedBy
            modifiedOn
          }
          mandatoryQuestionCount
          nonMandatoryQuestionCount
          answeredMandatoryQuestionCount
          answeredNonMandatoryQuestionCount
          dataStatus
          createdBy
          createdOn
          modifiedBy
          modifiedOn
        }
      }
    }
  }
`;
export const updateInspections = /* GraphQL */ `
  mutation UpdateInspections($updateInspectionsInput: [UpdateInspectionInput]!) {
    updateInspections(updateInspectionsInput: $updateInspectionsInput) {
      inspectionId
      recSeq
      recStatus
      orgId
      facilityId
      jobId
      inspectorId
      inspectionName
      inspectionDescription
      inspectionEmailRecipient
      inspectionReportUrl
      frequencyType
      inspectionStartDate
      inspectionEndDate
      plannedInspectionStartDate
      plannedInspectionEndDate
      facilityName
      facilityAddress
      facilityType
      vendorName
      jobName
      intId
      latitude
      longitude
      dataStatus
      createdBy
      createdOn
      modifiedBy
      modifiedOn
    }
  }
`;
export const createInspectionSections = /* GraphQL */ `
  mutation CreateInspectionSections(
    $inspectionId: String!
    $createInspectionSectionInput: [CreateInspectionSectionInput]!
  ) {
    createInspectionSections(inspectionId: $inspectionId, createInspectionSectionInput: $createInspectionSectionInput) {
      sectionId
      recSeq
      recStatus
      inspectionId
      sectionName
      sectionDescription
      displaySeq
      isProofRequired
      questions {
        questionId
        recSeq
        recStatus
        inspectionId
        sectionId
        questionText
        questionDescription
        questionNumber
        answerType
        isMandatory
        answerPattern
        parentQuestionId
        optionId
        answerOptions {
          answerId
          recSeq
          recStatus
          questionId
          displayText
          displaySeq
          answerDescription
          dataStatus
          createdBy
          createdOn
          modifiedBy
          modifiedOn
        }
        dataStatus
        createdBy
        createdOn
        modifiedBy
        modifiedOn
      }
      mandatoryQuestionCount
      nonMandatoryQuestionCount
      answeredMandatoryQuestionCount
      answeredNonMandatoryQuestionCount
      dataStatus
      createdBy
      createdOn
      modifiedBy
      modifiedOn
    }
  }
`;
export const updateInspectionSections = /* GraphQL */ `
  mutation UpdateInspectionSections($updateInspectionSectionsInput: [UpdateInspectionSectionInput]!) {
    updateInspectionSections(updateInspectionSectionsInput: $updateInspectionSectionsInput) {
      sectionId
      recSeq
      recStatus
      inspectionId
      sectionName
      sectionDescription
      displaySeq
      isProofRequired
      questions {
        questionId
        recSeq
        recStatus
        inspectionId
        sectionId
        questionText
        questionDescription
        questionNumber
        answerType
        isMandatory
        answerPattern
        parentQuestionId
        optionId
        answerOptions {
          answerId
          recSeq
          recStatus
          questionId
          displayText
          displaySeq
          answerDescription
          dataStatus
          createdBy
          createdOn
          modifiedBy
          modifiedOn
        }
        dataStatus
        createdBy
        createdOn
        modifiedBy
        modifiedOn
      }
      mandatoryQuestionCount
      nonMandatoryQuestionCount
      answeredMandatoryQuestionCount
      answeredNonMandatoryQuestionCount
      dataStatus
      createdBy
      createdOn
      modifiedBy
      modifiedOn
    }
  }
`;
export const createInspectionAnswers = /* GraphQL */ `
  mutation CreateInspectionAnswers($sectionId: String!, $createInspectionFormInput: CreateInspectionFormInput!) {
    createInspectionAnswers(sectionId: $sectionId, createInspectionFormInput: $createInspectionFormInput) {
      recordId
      recSeq
      recStatus
      answer
      questionId
      inspectionId
      inspectorId
      answerId
      dataStatus
      createdBy
      createdOn
      modifiedBy
      modifiedOn
    }
  }
`;
export const updateInspectionAnswers = /* GraphQL */ `
  mutation UpdateInspectionAnswers($sectionId: String!, $updateInspectionFormInput: UpdateInspectionFormInput!) {
    updateInspectionAnswers(sectionId: $sectionId, updateInspectionFormInput: $updateInspectionFormInput) {
      answers {
        recordId
        recSeq
        recStatus
        answer
        questionId
        inspectionId
        inspectorId
        answerId
        dataStatus
        createdBy
        createdOn
        modifiedBy
        modifiedOn
      }
      attachments {
        recordId
        recSeq
        recStatus
        inspectionId
        sectionId
        fileName
        url
        dataStatus
        createdBy
        createdOn
        modifiedBy
        modifiedOn
      }
    }
  }
`;
export const createBidAttachments = /* GraphQL */ `
  mutation CreateBidAttachments($bidId: String!, $createBidAttachmentInput: [CreateBidAttachmentInput]) {
    createBidAttachments(bidId: $bidId, createBidAttachmentInput: $createBidAttachmentInput) {
      attachmentId
      mdAttachmentType
      fileName
      mdFileContext
      fileExtension
      url
      dataStatus
      comments
      tags
    }
  }
`;
export const updateBidAttachments = /* GraphQL */ `
  mutation UpdateBidAttachments($updateBidAttachmentInput: [CreateBidAttachmentInput]) {
    updateBidAttachments(updateBidAttachmentInput: $updateBidAttachmentInput) {
      attachmentId
      mdAttachmentType
      fileName
      mdFileContext
      fileExtension
      url
      dataStatus
      comments
      tags
    }
  }
`;
export const deleteBidAttachments = /* GraphQL */ `
  mutation DeleteBidAttachments($bidId: String!, $attachmentIds: [String]) {
    deleteBidAttachments(bidId: $bidId, attachmentIds: $attachmentIds) {
      attachmentId
      mdAttachmentType
      fileName
      mdFileContext
      fileExtension
      url
      dataStatus
      comments
      tags
    }
  }
`;
export const addComments = /* GraphQL */ `
  mutation AddComments($keyId: String!, $commentsInput: CommentsInput) {
    addComments(keyId: $keyId, commentsInput: $commentsInput) {
      commentId
      keyId
      keyType
      comment
      commenterType
      dataStatus
    }
  }
`;
export const createConversation = /* GraphQL */ `
  mutation CreateConversation($conversationInput: ConversationInput!) {
    createConversation(conversationInput: $conversationInput) {
      data {
        conversationId
        customerId
        vendorId
        bidId
        bidVersion
        jobId
        jobName
        customerName
        vendorName
        countOfMessages
        messages {
          messageId
          message
          messageType
          isRead
          organizationId
          userName
          userRoleId
          userRoleName
          conversationContext
        }
      }
      metadata {
        totalCount
        pageIndex
        limit
      }
    }
  }
`;
export const createJobAttachments = /* GraphQL */ `
  mutation CreateJobAttachments(
    $jobId: String!
    $facilityIds: String!
    $createOrUpdateJobAttachmentInput: [CreateOrUpdateJobAttachmentInput]
  ) {
    createJobAttachments(
      jobId: $jobId
      facilityIds: $facilityIds
      createOrUpdateJobAttachmentInput: $createOrUpdateJobAttachmentInput
    ) {
      attachmentId
      mdAttachmentType
      fileName
      mdFileContext
      fileExtension
      url
      dataStatus
      comments
      jobId
      facilityId
      tags
    }
  }
`;
export const deleteJobAttachments = /* GraphQL */ `
  mutation DeleteJobAttachments($jobId: String!, $attachmentIds: [String]!) {
    deleteJobAttachments(jobId: $jobId, attachmentIds: $attachmentIds) {
      attachmentId
      mdAttachmentType
      fileName
      mdFileContext
      fileExtension
      url
      dataStatus
      comments
      jobId
      facilityId
      tags
    }
  }
`;
export const updateJobAttachments = /* GraphQL */ `
  mutation UpdateJobAttachments($createOrUpdateJobAttachmentInput: [CreateOrUpdateJobAttachmentInput]) {
    updateJobAttachments(createOrUpdateJobAttachmentInput: $createOrUpdateJobAttachmentInput) {
      attachmentId
      mdAttachmentType
      fileName
      mdFileContext
      fileExtension
      url
      dataStatus
      comments
      jobId
      facilityId
      tags
    }
  }
`;
export const addJobBid = /* GraphQL */ `
  mutation AddJobBid($jobId: String!, $vendorId: String!, $createJobBidInput: JobBidInput!) {
    addJobBid(jobId: $jobId, vendorId: $vendorId, createJobBidInput: $createJobBidInput) {
      bidId
      jobId
      bidName
      totalCost
      profitMargin
      finalQuote
      bidEstimationType
      bidCreationDate
      draftStep
      isFlatMargin
      draftStatus
      dataStatus
      bidTemplate
      bidVersion
      bidVersionCreatedDate
      submittedBy
      bidSubmissionDate
      mdFrequencyType
      comments
      bidCreatedFromSample
      errorCode
      vendorId
      vendorName
      vendorEmail
    }
  }
`;
export const submitBid = /* GraphQL */ `
  mutation SubmitBid($bidId: String!, $bidVendorInput: BidVendorInput) {
    submitBid(bidId: $bidId, bidVendorInput: $bidVendorInput) {
      bidId
      jobId
      bidName
      totalCost
      profitMargin
      finalQuote
      bidEstimationType
      bidCreationDate
      draftStep
      isFlatMargin
      draftStatus
      dataStatus
      bidTemplate
      bidVersion
      bidVersionCreatedDate
      submittedBy
      bidSubmissionDate
      mdFrequencyType
      comments
      bidCreatedFromSample
      errorCode
      vendorId
      vendorName
      vendorEmail
    }
  }
`;
export const updateBid = /* GraphQL */ `
  mutation UpdateBid($vendorId: String, $bidId: String!, $updateJobInput: UpdateJobBidInput) {
    updateBid(vendorId: $vendorId, bidId: $bidId, updateJobInput: $updateJobInput) {
      bidId
      jobId
      bidName
      totalCost
      profitMargin
      finalQuote
      bidEstimationType
      bidCreationDate
      draftStep
      isFlatMargin
      draftStatus
      dataStatus
      bidTemplate
      bidVersion
      bidVersionCreatedDate
      submittedBy
      bidSubmissionDate
      mdFrequencyType
      comments
      bidCreatedFromSample
      errorCode
      vendorId
      vendorName
      vendorEmail
    }
  }
`;
export const resendBidEmail = /* GraphQL */ `
  mutation ResendBidEmail($bidId: String!) {
    resendBidEmail(bidId: $bidId) {
      bidId
      jobId
      bidName
      totalCost
      profitMargin
      finalQuote
      bidEstimationType
      bidCreationDate
      draftStep
      isFlatMargin
      draftStatus
      dataStatus
      bidTemplate
      bidVersion
      bidVersionCreatedDate
      submittedBy
      bidSubmissionDate
      mdFrequencyType
      comments
      bidCreatedFromSample
      errorCode
      vendorId
      vendorName
      vendorEmail
    }
  }
`;
export const generateInvoice = /* GraphQL */ `
  mutation GenerateInvoice($bidId: String!, $jobId: String!, $generateInvoiceInput: generateInvoiceInput!) {
    generateInvoice(bidId: $bidId, jobId: $jobId, generateInvoiceInput: $generateInvoiceInput) {
      responseCode
    }
  }
`;
export const deleteJobBids = /* GraphQL */ `
  mutation DeleteJobBids($deleteJobBidsInput: DeleteJobBidInput!) {
    deleteJobBids(deleteJobBidsInput: $deleteJobBidsInput)
  }
`;
export const addJobBidAdditionalExpenses = /* GraphQL */ `
  mutation AddJobBidAdditionalExpenses(
    $jobId: String!
    $bidId: String!
    $createJobBidAdditionalExpensesInput: [CreateJobBidAdditionalExpensesInput]!
  ) {
    addJobBidAdditionalExpenses(
      jobId: $jobId
      bidId: $bidId
      createJobBidAdditionalExpensesInput: $createJobBidAdditionalExpensesInput
    ) {
      added {
        bidId
        bidAdditionalExpenseId
        name
        description
        bidSimpleEstimationEstimationId
        rate
        quantity
        totalCost
        mdUnits
        mdServiceCategoryId
        mdAdditionalExpenseCategory
        draftStatus
      }
      deleted {
        bidId
        bidAdditionalExpenseId
        name
        description
        bidSimpleEstimationEstimationId
        rate
        quantity
        totalCost
        mdUnits
        mdServiceCategoryId
        mdAdditionalExpenseCategory
        draftStatus
      }
      modified {
        bidId
        bidAdditionalExpenseId
        name
        description
        bidSimpleEstimationEstimationId
        rate
        quantity
        totalCost
        mdUnits
        mdServiceCategoryId
        mdAdditionalExpenseCategory
        draftStatus
      }
    }
  }
`;
export const addJobBidEstimation = /* GraphQL */ `
  mutation AddJobBidEstimation(
    $jobId: String!
    $bidId: String!
    $createJobBidEstimationInput: [CreateJobBidEstimationInput]!
  ) {
    addJobBidEstimation(jobId: $jobId, bidId: $bidId, createJobBidEstimationInput: $createJobBidEstimationInput) {
      added {
        jobId
        estimationId
        bidId
        mdCategoryId
        mdTaskId
        mdMeasureType
        rate
        quantity
        isOverTime
        totalCost
        isVendorAdded
        isBillable
        numberOfOccurrences
        mdUnits
        tasks {
          taskId
          taskName
        }
      }
      deleted {
        jobId
        estimationId
        bidId
        mdCategoryId
        mdTaskId
        mdMeasureType
        rate
        quantity
        isOverTime
        totalCost
        isVendorAdded
        isBillable
        numberOfOccurrences
        mdUnits
        tasks {
          taskId
          taskName
        }
      }
      modified {
        jobId
        estimationId
        bidId
        mdCategoryId
        mdTaskId
        mdMeasureType
        rate
        quantity
        isOverTime
        totalCost
        isVendorAdded
        isBillable
        numberOfOccurrences
        mdUnits
        tasks {
          taskId
          taskName
        }
      }
    }
  }
`;
export const updateJobBidEstimation = /* GraphQL */ `
  mutation UpdateJobBidEstimation($estimationId: String!, $updateJobBidEstimationInput: UpdateJobBidEstimationInput!) {
    updateJobBidEstimation(estimationId: $estimationId, updateJobBidEstimationInput: $updateJobBidEstimationInput) {
      jobId
      estimationId
      bidId
      mdCategoryId
      mdTaskId
      mdMeasureType
      rate
      quantity
      isOverTime
      totalCost
      isVendorAdded
      isBillable
      numberOfOccurrences
      mdUnits
      tasks {
        taskId
        taskName
      }
    }
  }
`;
export const addJobDueDates = /* GraphQL */ `
  mutation AddJobDueDates($customerId: String!, $jobId: String!, $createJobDueDatesInput: [CreateJobDueDateInput]!) {
    addJobDueDates(customerId: $customerId, jobId: $jobId, createJobDueDatesInput: $createJobDueDatesInput) {
      added {
        jobId
        mdDueType
        dueDescription
        dueDate
      }
      deleted {
        jobId
        mdDueType
        dueDescription
        dueDate
      }
      reactivated {
        jobId
        mdDueType
        dueDescription
        dueDate
      }
      modified {
        jobId
        mdDueType
        dueDescription
        dueDate
      }
    }
  }
`;
export const addJobFacility = /* GraphQL */ `
  mutation AddJobFacility($customerId: String!, $jobId: String!, $jobFacilityInput: [CreateJobFacilityInput]) {
    addJobFacility(customerId: $customerId, jobId: $jobId, jobFacilityInput: $jobFacilityInput) {
      added {
        jobId
        facilityId
        mdFacilityType
        facilityAddress
        remarks
        status
        jobStartDate
        jobEndData
      }
      deleted {
        jobId
        facilityId
        mdFacilityType
        facilityAddress
        remarks
        status
        jobStartDate
        jobEndData
      }
      reactivated {
        jobId
        facilityId
        mdFacilityType
        facilityAddress
        remarks
        status
        jobStartDate
        jobEndData
      }
    }
  }
`;
export const addJobFrequency = /* GraphQL */ `
  mutation AddJobFrequency($customerId: String!, $jobId: String!, $jobFrequencyInput: [CreateJobFrequencyInput]!) {
    addJobFrequency(customerId: $customerId, jobId: $jobId, jobFrequencyInput: $jobFrequencyInput) {
      added {
        mdShiftType
        mdScheduleType
        mdServiceId
        scheduleDays
        fromTime
        toTime
        cronExpression
        repeatitions
        repetition
      }
      deleted {
        mdShiftType
        mdScheduleType
        mdServiceId
        scheduleDays
        fromTime
        toTime
        cronExpression
        repeatitions
        repetition
      }
      reactivated {
        mdShiftType
        mdScheduleType
        mdServiceId
        scheduleDays
        fromTime
        toTime
        cronExpression
        repeatitions
        repetition
      }
      modified {
        mdShiftType
        mdScheduleType
        mdServiceId
        scheduleDays
        fromTime
        toTime
        cronExpression
        repeatitions
        repetition
      }
    }
  }
`;
export const addJobInterest = /* GraphQL */ `
  mutation AddJobInterest($customerId: String!, $vendorId: String, $createJobInterestInput: [CreateInputJobInterest]!) {
    addJobInterest(customerId: $customerId, vendorId: $vendorId, createJobInterestInput: $createJobInterestInput) {
      vendorId
      jobId
      actionType
      actionTime
      actionUser
    }
  }
`;
export const updateJobInterest = /* GraphQL */ `
  mutation UpdateJobInterest(
    $customerId: String!
    $vendorId: String
    $createJobInterestInput: CreateInputJobInterest!
  ) {
    updateJobInterest(customerId: $customerId, vendorId: $vendorId, createJobInterestInput: $createJobInterestInput) {
      vendorId
      jobId
      actionType
      actionTime
      actionUser
    }
  }
`;
export const markJobAsAwarded = /* GraphQL */ `
  mutation MarkJobAsAwarded($jobId: String!, $bidId: String!, $facilityAddress: facilityAddressInput) {
    markJobAsAwarded(jobId: $jobId, bidId: $bidId, facilityAddress: $facilityAddress) {
      jobId
      jobName
      specialInstructions
      startDate
      stopDate
      jobType
      customerId
      customerName
      jobCustodian
      dataStatus
      jobFacilities {
        jobId
        facilityId
        mdFacilityType
        facilityAddress
        remarks
        status
        jobStartDate
        jobEndData
      }
      jobServices {
        jobId
        mdServiceId
        serviceMeasure
        mdServiceUnits
        tasks {
          taskId
          taskName
        }
        actualMdServiceUnits
        actualServiceMeasure
      }
      jobFrequencies {
        mdShiftType
        mdScheduleType
        mdServiceId
        scheduleDays
        fromTime
        toTime
        cronExpression
        repeatitions
        repetition
      }
      jobVendors {
        jobId
        vendorId
        mdVendorType
      }
      jobDueDates {
        jobId
        mdDueType
        dueDescription
        dueDate
      }
      jobAttachments {
        attachmentId
        mdAttachmentType
        fileName
        mdFileContext
        fileExtension
        url
        dataStatus
        comments
        jobId
        facilityId
        tags
      }
    }
  }
`;
export const processBidReview = /* GraphQL */ `
  mutation ProcessBidReview(
    $transactionId: String!
    $bidStatus: String!
    $processBidReviewInput: processBidReviewInput
  ) {
    processBidReview(
      transactionId: $transactionId
      bidStatus: $bidStatus
      processBidReviewInput: $processBidReviewInput
    ) {
      responseCode
    }
  }
`;
export const addJob = /* GraphQL */ `
  mutation AddJob($customerId: String!, $jobsInput: CreateJobInput) {
    addJob(customerId: $customerId, jobsInput: $jobsInput) {
      jobId
      jobName
      startDate
      stopDate
      dataStatus
      specialInstructions
      draftStep
      draftStatus
      jobType
    }
  }
`;
export const deleteJobs = /* GraphQL */ `
  mutation DeleteJobs($deleteJobsInput: DeleteJobInput!) {
    deleteJobs(deleteJobsInput: $deleteJobsInput)
  }
`;
export const updateJob = /* GraphQL */ `
  mutation UpdateJob($customerId: String!, $jobId: String!, $jobsInput: UpdateJobInput) {
    updateJob(customerId: $customerId, jobId: $jobId, jobsInput: $jobsInput) {
      jobId
      jobName
      startDate
      stopDate
      dataStatus
      specialInstructions
      draftStep
      draftStatus
      jobType
    }
  }
`;
export const createSubJob = /* GraphQL */ `
  mutation CreateSubJob($createSubJobInput: CreateSubJobInput) {
    createSubJob(createSubJobInput: $createSubJobInput) {
      jobId
      jobName
      startDate
      stopDate
      dataStatus
      specialInstructions
      draftStep
      draftStatus
      jobType
    }
  }
`;
export const addJobServices = /* GraphQL */ `
  mutation AddJobServices($customerId: String!, $jobId: String!, $jobServicesInput: [CreateJobServiceInput!]) {
    addJobServices(customerId: $customerId, jobId: $jobId, jobServicesInput: $jobServicesInput) {
      added {
        jobId
        mdServiceId
        serviceMeasure
        mdServiceUnits
        tasks {
          taskId
          taskName
        }
        actualMdServiceUnits
        actualServiceMeasure
      }
      deleted {
        jobId
        mdServiceId
        serviceMeasure
        mdServiceUnits
        tasks {
          taskId
          taskName
        }
        actualMdServiceUnits
        actualServiceMeasure
      }
      reactivated {
        jobId
        mdServiceId
        serviceMeasure
        mdServiceUnits
        tasks {
          taskId
          taskName
        }
        actualMdServiceUnits
        actualServiceMeasure
      }
      modified {
        jobId
        mdServiceId
        serviceMeasure
        mdServiceUnits
        tasks {
          taskId
          taskName
        }
        actualMdServiceUnits
        actualServiceMeasure
      }
    }
  }
`;
export const addJobVendors = /* GraphQL */ `
  mutation AddJobVendors($customerId: String!, $jobId: String!, $createJobVendorInput: [CreateJobVendorInput]!) {
    addJobVendors(customerId: $customerId, jobId: $jobId, createJobVendorInput: $createJobVendorInput) {
      added {
        jobId
        vendorId
        mdVendorType
      }
      deleted {
        jobId
        vendorId
        mdVendorType
      }
      reactivated {
        jobId
        vendorId
        mdVendorType
      }
    }
  }
`;
