/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

// import React, { useEffect, useState } from 'react';
// import { API } from 'aws-amplify';
// import { findInspectionReportUrl } from '../../../graphql/queries';
// import { AWSAuthMode } from '../../Facility/Services/FacilityApiService';
// import { CustomHeaders } from '../../../Shared/Constants/App';
// import {
//   CircularProgress,
//   Button,
//   // Select, MenuItem, SelectChangeEvent
// } from '@mui/material';
// import jsPDF from 'jspdf';
// import html2canvas from 'html2canvas';
// import { Loader } from '../../Jobs/Components/JobView/JobsView.styles'; // Replace with your correct path
// export interface IGraphQLResponse {
//   data?: any;
//   errors?: any;
// }

// const Reports: React.FC = () => {
//   const [iframeUrl, setIframeUrl] = useState<string>('');
//   const [loading, setLoading] = useState<boolean>(true);

//   const fetchInspectionReportUrl = async () => {
//     try {
//       const associateProfileDetails: IGraphQLResponse = await API.graphql(
//         {
//           query: findInspectionReportUrl,
//           variables: {},
//           authMode: AWSAuthMode.COGNITO,
//         },
//         CustomHeaders
//       );
//       const reportUrl = associateProfileDetails?.data?.findInspectionReportUrl ?? '';
//       console.log('reportUrl', reportUrl);
//       setIframeUrl(reportUrl);
//     } catch (e: any) {
//       console.error('Error fetching the report URL:', e.errors);
//     } finally {
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     fetchInspectionReportUrl();
//   }, []);

//   const downloadPDF = async () => {
//     const element = document.getElementById('report-iframe-container'); // Target the container of the iframe

//     if (element) {
//       const canvas = await html2canvas(element);
//       const imgData = canvas.toDataURL('image/png');

//       const pdf = new jsPDF('p', 'mm', 'a4');
//       const imgWidth = 210; // A4 width in mm
//       const imgHeight = (canvas.height * imgWidth) / canvas.width;

//       pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
//       pdf.save('dashboard-report.pdf');
//     } else {
//       console.error('Element not found for PDF generation.');
//     }
//   };

//   if (loading) {
//     return (
//       <Loader>
//         <CircularProgress style={{ marginTop: '2rem', color: '#006B6B' }} />
//       </Loader>
//     );
//   }

//   if (!iframeUrl) {
//     return <div>No report available</div>;
//   }

//   return (
//     <div>
//       {/* Container around the iframe for capturing */}
//       <div id="report-iframe-container" style={{ width: '100%', height: '500px' }}>
//         <iframe
//           id="report-iframe"
//           src={iframeUrl}
//           frameBorder="0"
//           width="100%"
//           height="100%"
//           title="Metabase Dashboard"
//           allowTransparency
//         />
//       </div>
//       <Button variant="contained" color="primary" onClick={downloadPDF} style={{ marginTop: '1rem' }}>
//         Download PDF
//       </Button>
//     </div>
//   );
// };

// export default Reports;

// const Reports: React.FC = () => {
//   const [iframeUrl, setIframeUrl] = useState<string>('');
//   const [loading, setLoading] = useState<boolean>(true);
//   const [exportFormat, setExportFormat] = useState<string>('csv'); // default export format

//   const fetchInspectionReportUrl = async () => {
//     try {
//       const associateProfileDetails: IGraphQLResponse = await API.graphql(
//         {
//           query: findInspectionReportUrl,
//           variables: {},
//           authMode: AWSAuthMode.COGNITO,
//         },
//         CustomHeaders
//       );
//       const reportUrl = associateProfileDetails?.data?.findInspectionReportUrl ?? '';
//       console.log('reportUrl', reportUrl);
//       setIframeUrl(reportUrl);
//     } catch (e: any) {
//       console.error('Error fetching the report URL:', e.errors);
//     } finally {
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     fetchInspectionReportUrl();
//   }, []);

//   const handleFormatChange = (event: SelectChangeEvent<string>) => {
//     setExportFormat(event.target.value);
//   };

//   const downloadReport = async () => {
//     const dashboardId = 2; // Replace with your dashboard ID
//     const dashcardId = 1; // Replace with your dashcard ID
//     const cardId = 3; // Replace with your card ID
//     const baseUrl = 'http://3.208.1.118:3000'; // Replace with your Metabase instance URL

//     try {
//       const response = await fetch(
//         `${baseUrl}/api/dashboard/${dashboardId}/dashcard/${dashcardId}/card/${cardId}/query/${exportFormat}?parameters={}`,
//         {
//           method: 'POST',
//           headers: { Accept: 'application/json', 'Content-Type': 'application/json', 'X-PZY-Client-Type': 'Web' },
//         }
//       );

//       if (!response.ok) {
//         throw new Error('Failed to download the report');
//       }

//       const blob = await response.blob();
//       const url = window.URL.createObjectURL(blob);
//       const a = document.createElement('a');
//       a.href = url;
//       a.download = `report.${exportFormat}`;
//       document.body.appendChild(a);
//       a.click();
//       document.body.removeChild(a);
//     } catch (error) {
//       console.error('Error downloading the report:', error);
//     }
//   };

//   if (loading) {
//     return (
//       <Loader>
//         <CircularProgress style={{ marginTop: '2rem', color: '#006B6B' }} />
//       </Loader>
//     );
//   }

//   if (!iframeUrl) {
//     return <div>No report available</div>;
//   }

//   return (
//     <div>
//       <iframe
//         id="report-iframe"
//         src={iframeUrl}
//         frameBorder="0"
//         width="100%"
//         height="500px"
//         title="Metabase Dashboard"
//         allowTransparency
//       />
//       <div style={{ marginTop: '1rem' }}>
//         <Select value={exportFormat} onChange={handleFormatChange}>
//           <MenuItem value="csv">CSV</MenuItem>
//           <MenuItem value="xlsx">XLSX</MenuItem>
//           <MenuItem value="json">JSON</MenuItem>
//         </Select>
//         <Button variant="contained" color="primary" onClick={downloadReport} style={{ marginLeft: '1rem' }}>
//           Download Report
//         </Button>
//       </div>
//     </div>
//   );
// };

// export default Reports;

import React, { useEffect, useState } from 'react';
import { API } from 'aws-amplify';
import { findInspectionReportUrl } from '../../../graphql/queries';
import { AWSAuthMode } from '../../Facility/Services/FacilityApiService';
import { CustomHeaders } from '../../../Shared/Constants/App';
import { CircularProgress, Button } from '@mui/material';
import { Loader } from '../../Jobs/Components/JobView/JobsView.styles';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

export interface IGraphQLResponse {
  data?: any;
  errors?: any;
}

const Reports: React.FC = () => {
  const [iframeUrl, setIframeUrl] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);

  const fetchInspectionReportUrl = async () => {
    try {
      const associateProfileDetails: IGraphQLResponse = await API.graphql(
        {
          query: findInspectionReportUrl,
          variables: {},
          authMode: AWSAuthMode.COGNITO,
        },
        CustomHeaders
      );
      const reportUrl = associateProfileDetails?.data?.findInspectionReportUrl ?? '';
      console.log('reportUrl', reportUrl);
      setIframeUrl(reportUrl);
    } catch (e: any) {
      console.error('Error fetching the report URL:', e.errors);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchInspectionReportUrl();
  }, []);

  // const downloadPDF = async () => {
  //   const iframe = document.getElementById('report-iframe') as HTMLIFrameElement;
  //   console.log('iframe', iframe);

  //   // Check if the iframe content is accessible
  //   if (iframe.contentWindow) {
  //     console.log('Posting message to iframe');

  //     try {
  //       // Post a message to the iframe window
  //       const ar = {};
  //       iframe.contentWindow.postMessage(ar, ' https://metabase-1441086961.us-east-1.elb.amazonaws.com/');
  //       console.log('ar', ar);
  //     } catch (error) {
  //       console.error('Error posting message to iframe:', error);
  //     }

  //     // Add an event listener to capture the message from the iframe
  //     window.addEventListener('message', (event) => {
  //       console.log('event.origin', event.origin, event.data, event);
  //       if (event.origin === 'https://metabase-1441086961.us-east-1.elb.amazonaws.com/') {
  //         console.log('Message from iframe:', event.data);
  //       } else {
  //         console.error('Invalid origin, message not from trusted source');
  //       }
  //     });
  //   }

  //   // Check if iframe content is ready and accessible
  //   iframe.onload = async () => {
  //     console.log('iframe', iframe);
  //     if (iframe && iframe.contentDocument) {
  //       const iframeDocument = iframe.contentDocument;
  //       console.log('iframeDocument', iframeDocument);
  //       try {
  //         // Use html2canvas to capture the iframe content as an image
  //         const canvas = await html2canvas(iframeDocument.body);
  //         const imgData = canvas.toDataURL('image/png');

  //         // Create a jsPDF instance
  //         const pdf = new jsPDF('p', 'mm', 'a4');
  //         const imgWidth = 210; // A4 page width in mm
  //         const imgHeight = (canvas.height * imgWidth) / canvas.width;
  //         pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);

  //         // Save the generated PDF
  //         pdf.save('report.pdf');
  //         console.log('PDF successfully generated and saved');
  //       } catch (error) {
  //         console.error('Error capturing iframe content and generating PDF:', error);
  //       }
  //     } else {
  //       console.error('Unable to access iframe content');
  //     }
  //   };
  // };

  const handleMessage = async (event: MessageEvent) => {
    // Check the origin of the message to ensure it's coming from the correct domain
    console.log('Received event:', event.origin, event.data);
    if (event.origin === 'https://dashboard-dev.abrightlab.com') {
      // Process the message from the iframe
      console.log('Message from iframe:', event.data);
      if (event.data && event.data.htmlContent) {
        // Now that you have the content, you can render it to canvas and save as PDF
        try {
          // Use html2canvas to capture the iframe content as an image
          const temporaryElement = document.createElement('div');
          temporaryElement.innerHTML = event.data.htmlContent;
          document.body.appendChild(temporaryElement);

          const canvas = await html2canvas(temporaryElement);
          const imgData = canvas.toDataURL('image/png');

          // Create a jsPDF instance
          const pdf = new jsPDF('p', 'mm', 'a4');
          const imgWidth = 210; // A4 page width in mm
          const imgHeight = (canvas.height * imgWidth) / canvas.width;
          pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);

          // Save the generated PDF
          pdf.save('report.pdf');
          console.log('PDF successfully generated and saved');

          // Cleanup the temporary element after use
          document.body.removeChild(temporaryElement);
        } catch (error) {
          console.error('Error generating PDF from iframe content:', error);
        }
      } else {
        console.error('No HTML content received from iframe');
      }

      // Cleanup event listener after use
      window.removeEventListener('message', handleMessage);
    } else {
      console.error('Invalid origin, message not from trusted source');
    }
  };

  const downloadPDF = async () => {
    const iframe = document.getElementById('report-iframe') as HTMLIFrameElement;

    // Check if the iframe content is accessible
    if (iframe.contentWindow) {
      console.log('Posting message to iframe');

      try {
        // Post a message to the iframe window to trigger some action inside the iframe
        const message = { action: 'getContent' }; // Action message that the iframe understands
        iframe.contentWindow.postMessage(message, 'https://dashboard-dev.abrightlab.com');
        console.log('Message posted:', message);
      } catch (error) {
        console.error('Error posting message to iframe:', error);
      }

      // Add an event listener to capture the message from the iframe

      window.addEventListener('message', handleMessage);
    }

    // Fallback: If you have access to the iframe content directly (same origin)
    iframe.onload = async () => {
      console.log('iframe loaded', iframe);
      if (iframe && iframe.contentDocument) {
        const iframeDocument = iframe.contentDocument;
        console.log('iframeDocument:', iframeDocument);
        try {
          // Use html2canvas to capture the iframe content as an image
          const canvas = await html2canvas(iframeDocument.body);
          const imgData = canvas.toDataURL('image/png');

          // Create a jsPDF instance
          const pdf = new jsPDF('p', 'mm', 'a4');
          const imgWidth = 210; // A4 page width in mm
          const imgHeight = (canvas.height * imgWidth) / canvas.width;
          pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);

          // Save the generated PDF
          pdf.save('report.pdf');
          console.log('PDF successfully generated and saved');
        } catch (error) {
          console.error('Error capturing iframe content and generating PDF:', error);
        }
      } else {
        console.error('Unable to access iframe content');
      }
    };
  };

  // Ensure that the necessary libraries are imported
  // <script src="https://cdnjs.cloudflare.com/ajax/libs/html2canvas/0.4.1/html2canvas.min.js"></script>
  // <script src="https://cdnjs.cloudflare.com/ajax/libs/jspdf/1.5.3/jspdf.debug.js"></script>

  // const downloadPDF = async () => {
  //   const iframe = document.getElementById('report-iframe') as HTMLIFrameElement;

  //   // Check if the iframe content is accessible
  //   if (iframe.contentWindow) {
  //     console.log('Posting message to iframe');

  //     try {
  //       // Post a message to the iframe window to trigger some action inside the iframe
  //       const message = { action: 'getContent' }; // Action message that the iframe understands
  //       iframe.contentWindow.postMessage(message, 'https://dashboard-dev.abrightlab.com');
  //       console.log('Message posted:', message);
  //     } catch (error) {
  //       console.error('Error posting message to iframe:', error);
  //     }

  //     // Add an event listener to capture the message from the iframe
  //     window.addEventListener('message', async (event) => {
  //       // Check the origin of the message to ensure it's coming from the correct domain
  //       console.log('Received event:', event.origin, event.data);
  //       if (event.origin === 'https://dashboard-dev.abrightlab.com') {
  //         // Process the message from the iframe
  //         console.log('Message from iframe:', event.data);
  //         if (event.data && event.data.htmlContent) {
  //           // Now that you have the content, you can render it to canvas and save as PDF
  //           try {
  //             // Use html2canvas to capture the iframe content as an image
  //             const temporaryElement = document.createElement('div');
  //             temporaryElement.innerHTML = event.data.htmlContent;
  //             document.body.appendChild(temporaryElement);

  //             const canvas = await html2canvas(temporaryElement);
  //             const imgData = canvas.toDataURL('image/png');

  //             // Create a jsPDF instance
  //             const pdf = new jsPDF('p', 'mm', 'a4');
  //             const imgWidth = 210; // A4 page width in mm
  //             const imgHeight = (canvas.height * imgWidth) / canvas.width;
  //             pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);

  //             // Save the generated PDF
  //             pdf.save('report.pdf');
  //             console.log('PDF successfully generated and saved');
  //           } catch (error) {
  //             console.error('Error generating PDF from iframe content:', error);
  //           }
  //         } else {
  //           console.error('No HTML content received from iframe');
  //         }
  //       } else {
  //         console.error('Invalid origin, message not from trusted source');
  //       }
  //     });
  //   }

  //   // Fallback: If you have access to the iframe content directly (same origin)
  //   iframe.onload = async () => {
  //     console.log('iframe loaded', iframe);
  //     if (iframe && iframe.contentDocument) {
  //       const iframeDocument = iframe.contentDocument;
  //       console.log('iframeDocument:', iframeDocument);
  //       try {
  //         // Use html2canvas to capture the iframe content as an image
  //         const canvas = await html2canvas(iframeDocument.body);
  //         const imgData = canvas.toDataURL('image/png');

  //         // Create a jsPDF instance
  //         const pdf = new jsPDF('p', 'mm', 'a4');
  //         const imgWidth = 210; // A4 page width in mm
  //         const imgHeight = (canvas.height * imgWidth) / canvas.width;
  //         pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);

  //         // Save the generated PDF
  //         pdf.save('report.pdf');
  //         console.log('PDF successfully generated and saved');
  //       } catch (error) {
  //         console.error('Error capturing iframe content and generating PDF:', error);
  //       }
  //     } else {
  //       console.error('Unable to access iframe content');
  //     }
  //   };
  // };

  // Ensure that the necessary libraries are imported
  // <script src="https://cdnjs.cloudflare.com/ajax/libs/html2canvas/0.4.1/html2canvas.min.js"></script>
  // <script src="https://cdnjs.cloudflare.com/ajax/libs/jspdf/1.5.3/jspdf.debug.js"></script>

  // Ensure html2canvas and jsPDF libraries are imported in your HTML file
  // <script src="https://cdnjs.cloudflare.com/ajax/libs/html2canvas/0.4.1/html2canvas.min.js"></script>
  // <script src="https://cdnjs.cloudflare.com/ajax/libs/jspdf/1.5.3/jspdf.debug.js"></script>

  // const downloadPDF = async () => {
  //   const iframe = document.getElementById('report-iframe') as HTMLIFrameElement;
  //   console.log('iframe', iframe);
  //   // console.log('iframe', iframe.);
  //   console.log('iframe', iframe.contentWindow);
  //   if (iframe.contentWindow) {
  //     console.log('coming');
  //     iframe.contentWindow.postMessage({}, 'https://dashboard-dev.abrightlab.com');
  //     window.addEventListener('message', (event) => {
  //       // IMPORTANT: check the origin of the data!
  //       console.log('event.origin', event.origin, event.data);
  //       if (event.origin === 'https://your-first-site.example') {
  //         // The data was sent from your site.
  //         // Data sent with postMessage is stored in event.data:
  //         console.log(event.data);
  //       } else {
  //         // The data was NOT sent from your site!
  //         // Be careful! Do not use it. This else branch is
  //         // here just for clarity, you usually shouldn't need it.
  //         return;
  //       }
  //     });
  //   }

  //   if (iframe && iframe.contentDocument) {
  //     const iframeDocument = iframe.contentDocument;

  //     // Use html2canvas to capture the iframe's content as an image
  //     const canvas = await html2canvas(iframeDocument.body);
  //     const imgData = canvas.toDataURL('image/png');

  //     // Create a jsPDF instance and add the image to the PDF
  //     const pdf = new jsPDF('p', 'mm', 'a4');
  //     const imgWidth = 210; // A4 page width in mm
  //     const imgHeight = (canvas.height * imgWidth) / canvas.width;
  //     pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
  //     pdf.save('report.pdf');
  //   } else {
  //     console.error('Unable to access iframe content');
  //   }
  // };

  if (loading) {
    return (
      <Loader>
        <CircularProgress style={{ marginTop: '2rem', color: '#006B6B' }} />
      </Loader>
    );
  }

  if (!iframeUrl) {
    return <div>No report available</div>;
  }

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <iframe
        id="report-iframe"
        src={iframeUrl}
        frameBorder="0"
        width="100%"
        height="100%"
        title="Metabase Dashboard"
        allowTransparency
      />
      <Button variant="contained" color="primary" onClick={downloadPDF} style={{ marginTop: '1rem' }}>
        Download PDF
      </Button>
    </div>
  );
};

export default Reports;

// import React, { useEffect, useState, useRef } from 'react';
// import { API } from 'aws-amplify';
// import { findInspectionReportUrl } from '../../../graphql/queries';
// import { AWSAuthMode } from '../../Facility/Services/FacilityApiService';
// import { CustomHeaders } from '../../../Shared/Constants/App';
// import { CircularProgress } from '@mui/material';
// import { Loader } from '../../Jobs/Components/JobView/JobsView.styles';
// import html2canvas from 'html2canvas';
// import jsPDF from 'jspdf';

// export interface IGraphQLResponse {
//   data?: any;
//   errors?: any;
// }

// const Reports: React.FC = () => {
//   const [iframeUrl, setIframeUrl] = useState<string>('');
//   const [loading, setLoading] = useState<boolean>(true);
//   const iframeRef = useRef<HTMLIFrameElement | null>(null);

//   const fetchInspectionReportUrl = async () => {
//     try {
//       const associateProfileDetails: IGraphQLResponse = await API.graphql(
//         {
//           query: findInspectionReportUrl,
//           variables: {},
//           authMode: AWSAuthMode.COGNITO,
//         },
//         CustomHeaders
//       );
//       const reportUrl = associateProfileDetails?.data?.findInspectionReportUrl ?? '';
//       setIframeUrl(reportUrl);
//     } catch (e: any) {
//       console.error('Error fetching the report URL:', e.errors);
//     } finally {
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     fetchInspectionReportUrl();
//   }, []);

//   const downloadPDF = async () => {
//     if (iframeRef.current) {
//       console.log('iframeRef.current', iframeRef.current);
//       console.log('iframeRef.current', iframeRef.current.contentDocument);
//       try {
//         // Use html2canvas to capture the iframe content
//         const iframeWindow = iframeRef.current.contentWindow;
//         const iframeDocument = iframeWindow?.document;

//         if (iframeDocument) {
//           const iframeBody = iframeDocument.body;
//           const canvas = await html2canvas(iframeBody);

//           const imgData = canvas.toDataURL('image/png');
//           const pdf = new jsPDF('p', 'mm', 'a4');
//           const pdfWidth = pdf.internal.pageSize.getWidth();
//           const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

//           pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
//           pdf.save('report.pdf');
//         }
//       } catch (error) {
//         console.error('Error capturing iframe content:', error);
//       }
//     }
//   };

//   if (loading) {
//     return (
//       <Loader>
//         <CircularProgress style={{ marginTop: '2rem', color: '#006B6B' }} />
//       </Loader>
//     );
//   }

//   if (!iframeUrl) {
//     return <div>No report available</div>;
//   }

//   return (
//     <div style={{ width: '100%', height: '100%' }}>
//       <iframe
//         ref={iframeRef}
//         src={iframeUrl}
//         frameBorder="0"
//         width="100%"
//         height="100%"
//         title="Metabase Dashboard"
//         allowTransparency
//       />
//       <button onClick={downloadPDF}>Download PDF</button>
//     </div>
//   );
// };

// export default Reports;
